import React from "react";
import "./LoanAgreement.css";
import { Grid } from "@mui/material";
import Proptypes from "prop-types";
import { ImageBaseUrl } from "../../Environment";
import moment from "moment";

const tableStaticData = [];

export const LoanAgreement = ({ data, printRef }) => {
  const companyData = data?.company;
  const userData = data?.user;
  const customerData = data?.customer;
  const customerDataAddresses = data?.customer?.addresses[0];
  const customerDataBankDetail = data?.customer?.bankDetail;
  const customerDataCoApplicant = data?.customer?.coApplicant;
  const customerDataNominee = data?.customer?.nominee;
  const customerDataGuarantor = data?.customer?.guarantor;
  const customerDataLoanType = data?.loanType;

  const loanCollections = data?.loanCollections;
  const firstLoanCollection = data?.loanCollections[0];

  const {
    loanTenureType,
    loanTenure,
    processFee,
    collectionAmount,
    loanAmount,
    loanId,
    method,
  } = data;

  const getAddress = (address) =>
    [
      address?.AddressStreet,
      address?.AddressLandmark,
      address?.AddressCity,
      address?.AddressState,
      address?.AddressPincode,
    ]
      .filter(Boolean)
      .join(", ");

  const customerAddress = (address) =>
    [address?.AddressStreet, address?.AddressLandmark]
      .filter(Boolean)
      .join(", ");

  const customerAddress2 = (address) =>
    [address?.AddressCity, address?.AddressState, address?.AddressPincode]
      .filter(Boolean)
      .join(", ");
  return (
    <div ref={printRef} className="loan-agreement">
      <Grid container>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          paddingX={2}
          style={{ border: "1px solid black" }}
        >
          <div className="header-left">
            {userData?.userImagePath || companyData?.profilePic ? (
              <img
                src={
                  localStorage.getItem("loggedinUserCompany") == "1365"
                    ? "https://i.imghippo.com/files/CCD2780qQ.jpeg"
                    : `${ImageBaseUrl}${userData?.userImagePath || companyData?.profilePic}`
                }
                alt="Logo"
                className="logo"
              />
            ) : (
              <div
                style={{
                  height: "120px",
                }}
              ></div>
            )}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          paddingX={2}
          style={{ border: "1px solid black", borderRightWidth: "0px" }}
        >
          <div className="header-right">
            <h1>{companyData?.companyName}</h1>
            <p>
              <strong>Address: </strong> {getAddress(companyData)}
              <br />
              <strong>GSTIN: </strong> {companyData?.Gstin} |{" "}
              <strong>Website: </strong> {companyData?.companyWebsite}
              <br />
              <strong>Mobile No: </strong> {companyData?.phone}{" "}
              <strong>| Email ID: </strong> {companyData?.companyMail}
            </p>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          paddingX={2}
          style={{ border: "1px solid black", borderLeftWidth: "0px" }}
        />
      </Grid>
      <h2 className="title">Loan Agreement</h2>
      <div className="content">
        <div className="section">
          <table className="info-table">
            <tbody>
              <tr>
                <td colspan={2}>
                  <strong>Branch : </strong> <br />{" "}
                  <strong>Branch Center : </strong>
                </td>
                <td colspan={2}>
                  <strong>Date : </strong>
                  {moment().format("DD-MM-YYYY")} |{" "}
                  <strong>First EMI Date : </strong>
                  {moment(firstLoanCollection?.dueDate).format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Customer Name</strong> <br />{" "}
                  {customerData?.firstName + ` (${customerData?.cust_id})`}
                </td>
                <td>
                  <strong>Address</strong> <br />{" "}
                  {customerAddress(customerDataAddresses)}
                </td>
                <td>
                  <strong>District/State/Pincode</strong> <br />{" "}
                  {customerAddress2(customerDataAddresses)}
                </td>
                <td rowSpan={3}>
                  <div className="imgBox" />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Contact No</strong> <br /> {customerData?.phone}
                </td>
                <td>
                  <strong>Gender : </strong> {customerData?.gender}
                  <br /> <strong>DOB : </strong>
                  {moment(customerData?.dob).format("DD-MM-YYYY")}
                </td>
                <td>
                  <strong>C/O</strong> <br /> {customerData?.fatherName}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Co-Applicant : </strong> <br />{" "}
                  {customerDataCoApplicant?.coApplicantName}
                </td>
                <td>
                  <strong>Co-Applicant Address : </strong> <br />{" "}
                  {customerDataCoApplicant?.coApplicantAddress}
                </td>
                <td>
                  <strong>Co-Applicant Phone : </strong> <br />{" "}
                  {customerDataCoApplicant?.coApplicantMobNo}
                </td>
              </tr>
              <tr>
                <td colspan={2}>
                  <h3>Bank Details</h3>
                </td>
                <td colspan={2}>
                  <h3>Applied Loan Details</h3>
                </td>
              </tr>

              <tr>
                <td>Bank : {customerDataBankDetail?.bankName}</td>
                <td>Branch : {customerDataBankDetail?.bankBranch}</td>
                <td>Loan ID : {loanId}</td>
                <td>Processing Fee : {processFee}</td>
              </tr>
              <tr>
                <td>
                  Account Number : {customerDataBankDetail?.accountNumber}
                </td>
                <td>Account Type : {customerDataBankDetail?.accountType}</td>
                <td colSpan={2}>
                  Amount to be Paid :{" "}
                  {parseFloat(collectionAmount) * parseFloat(loanTenure)}
                </td>
              </tr>
              <tr>
                <td>Account Holder : {customerDataBankDetail?.acHolderName}</td>
                <td>IFSC : {customerDataBankDetail?.ifsc}</td>
                <td colSpan={2}>Loan Amount : {loanAmount}</td>
              </tr>
              <tr>
                <td colspan={2}>
                  <h3>Income Details</h3>
                </td>
                <td>Loan Type : {customerDataLoanType?.loanType}</td>
                <td>Interest Amt : {collectionAmount}</td>
              </tr>

              <tr>
                <td>Income (INR) : {customerData?.annualIncome}</td>
                <td>Occupation : {customerData?.incomeSource}</td>
                <td>Recovery type : {loanTenureType}</td>
                <td>No of Payments : {loanTenure}</td>
              </tr>
              <tr>
                <td colspan={3}>
                  <h3>Guarantor Details</h3>
                </td>
                <td rowSpan={3}>
                  <div className="imgBox" />
                </td>
              </tr>
              <tr>
                <td>Name : {customerDataGuarantor?.guarantorName}</td>
                <td>Address : {customerDataGuarantor?.guarantorAddress}</td>
                <td>
                  Contact Number : {customerDataGuarantor?.guarantorMobNo}
                </td>
              </tr>
              <tr>
                <td>Nominee Name : {customerDataNominee?.nomineeName}</td>
                <td>Nominee Mobile : {customerDataNominee?.nomineeMobNo}</td>
                <td>
                  Nominee DOB :{" "}
                  {moment(customerDataNominee?.nomineeDOB).format("DD-MM-YYYY")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="footer section">
          <table
            className="footer-table"
            style={{ width: "100%", borderCollapse: "collapse" }}
          >
            <tbody>
              <tr>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  <strong>
                    Member Signature
                    <br />
                    (सदस्या के हस्ताक्षर)
                  </strong>
                  <br />
                  <span style={{ marginTop: 30 }} className="input-line"></span>
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  <strong>
                    Spouse Signature
                    <br />
                    (जीवनसाथी के हस्ताक्षर)
                  </strong>
                  <br />
                  <span style={{ marginTop: 30 }} className="input-line"></span>
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  <strong>
                    Guarantor Signature
                    <br />
                    (जमानतदार के हस्ताक्षर)
                  </strong>
                  <br />
                  <span style={{ marginTop: 30 }} className="input-line"></span>
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  <strong>
                    Branch Seal With Signature
                    <br />
                    (शाखा मोहर के साथ हस्ताक्षर)
                  </strong>
                  <br />
                  <span style={{ marginTop: 30 }} className="input-line"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* New page break */}
        <div className="page-break"></div>

        <div className="section">
          <h2 className="title">Repayment Schedule Terms</h2>

          <table className="info-table">
            <thead>
              <th>Sr. No</th>
              <th>EMI Date</th>
              {method == "Collection method" ? (
                <>
                  <th>Opening Balance</th>
                  <th>Closing Balance</th>
                </>
              ) : (
                <>
                  <th>Opening Balance</th>
                  <th>Closing Balance</th>
                  <th>Interest</th>
                  <th>Principal</th>
                </>
              )}
              <th>Installment</th>
            </thead>
            <tbody>
              {loanCollections?.map((data, i) => {
                const totalRows = loanCollections.length;
                const maxPageBreaks = Math.floor(totalRows / 25); // Calculate the maximum number of page breaks
                return (
                  <React.Fragment key={data?.srNo}>
                    <tr>
                      <td style={{ width: "10%" }}>{i + 1}</td>
                      <td style={{ width: "30%" }}>
                        {moment(data?.dueDate).format("DD-MM-YYYY")}
                      </td>
                      {method == "Collection method" ? (
                        <>
                          <td style={{ width: "20%" }}>
                            {data?.openingBalance}
                          </td>
                          <td style={{ width: "20%" }}>
                            {data?.closingBalance}
                          </td>
                        </>
                      ) : (
                        <>
                          <td style={{ width: "10%" }}>
                            {data?.openingBalance}
                          </td>
                          <td style={{ width: "10%" }}>
                            {data?.closingBalance}
                          </td>
                          <td style={{ width: "10%" }}>{data?.interest}</td>
                          <td style={{ width: "10%" }}>{data?.principal}</td>
                        </>
                      )}
                      <td style={{ width: "20%" }}>{data?.Installment}</td>
                    </tr>
                    {(i + 1) % 25 === 0 &&
                      (i + 1) / 25 <= maxPageBreaks &&
                      i + 1 < totalRows && <tr className="page-break"></tr>}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* New page break */}
        <div className="page-break"></div>

        <div className="section">
          <h2 className="title">नियम और शर्तें</h2>

          <ol style={{ fontSize: "14px" }}>
            <li>
              उपरोक्त सभी पक्ष निम्नलिखित सभी शर्तों व नियमों पर अपनी सहमति
              व्यक्त करते हैं कि ऋणधारी इस राशि का उपयोग माइक्रोफाइनेंस की
              नियमावली समझकर करेंगे तथा इस राशि या इसके किसी भी अंश का उपयोग
              अन्य किसी भी उद्देश्य की पूर्ति हेतु नहीं करेंगे जिसके लिए इसे
              स्वीकृत नहीं किया गया है। हम इसके लिए भी सहमति व्यक्त करते हैं कि
              यदि कंपनी को ऐसा कोई कारण मिलता है जो स्पष्ट करता है कि शर्तों की
              पालना नहीं की गई है, तो कंपनी को अधिकार है कि वह इस ऋण राशि या
              उसके अंश को बिना किसी रियायत के वापस ले ले।
            </li>
            <li>
              मैं/हम, लोन के लिए आवेदन करने वाले तथा गारंटर, पूर्व में किसी भी
              गैर-कानूनी कार्य में लिप्त नहीं हैं और न ही किसी न्यायालय में
              हमारे विरुद्ध आपराधिक मामला विचाराधीन है और न ही किसी भी थाने में
              कोई एफआईआर दर्ज है।
            </li>
            <li>
              यह है कि मैं/हम किसी वित्तीय संस्थान, बैंक या फाइनेंस कंपनी द्वारा
              डिफॉल्टर घोषित नहीं किए गए हैं।
            </li>
            <li>
              यह है कि मैं/हम उक्त लोन की संपूर्ण किस्तों का भुगतान करने में
              पूरी तरह से सक्षम हैं और किसी भी विपरीत परिस्थिति में मैं/हम
              भुगतान करने में देरी नहीं करेंगे।
            </li>
            <li>
              यह है कि कंपनी का जो भी कर्मचारी किस्त की राशि का संग्रहण करने
              आएगा, मैं उसके साथ विनम्र व्यवहार रखूंगा/रखेंगे एवं उसे किसी भी
              प्रकार की आपत्तिजनक बात नहीं कहूंगा/कहेंगे। किसी भी प्रकार का उसे
              नुकसान या मारपीट की कोशिश नहीं करेंगे और न ही उसके खिलाफ गंदे
              व्यवहार या अपशब्द बोलने का आरोप लगाएंगे। अगर हमारे द्वारा कोई भी
              अभद्र व्यवहार किया जाता है, तो कंपनी कर्मचारी तथा कंपनी हमारे
              प्रति कानूनी कार्रवाई करने के लिए स्वतंत्र होंगे।
            </li>
            <li>
              यह है कि मैं/हम, नियत तिथि की सूचना देने से संबंधित जानकारी अपनी
              किस्तों के संपूर्ण भुगतान के लिए नोटिस, रिमाइंडर व इलेक्ट्रॉनिक
              सूचना, फोन इत्यादि देने के लिए कंपनी जिम्मेदार नहीं होगी। इस बात
              की याददाश्त से संबंधित संपूर्ण जिम्मेदारी मेरी/हमारी होगी।
            </li>
            <li>
              यह है कि अगर ऋणी या गारंटर अपने व्यवसाय, नौकरी, मूल निवास, मोबाइल
              नंबर या ईमेल आईडी का परिवर्तन करता है, तो इस बात की सूचना कंपनी को
              पूर्व में लिखित रूप में प्रदान करेंगे।
            </li>
            <li>
              यह है कि मैं/हम यह वचनबद्ध बयान करते हैं कि भविष्य में यदि हमारे
              द्वारा किस्तों का भुगतान चेक के माध्यम से किया जाता है, तो हम दिए
              गए चेकों को बैंक में स्टॉप नहीं कराएंगे और न ही उक्त चेक के खोने
              की रिपोर्ट थाने में दर्ज कराएंगे और न ही उक्त खाते को बंद कराएंगे।
              यदि हम ऐसा करते हैं, तो कंपनी हमारे विरुद्ध कानूनी कार्रवाई करने
              का अधिकार रखेगी।
            </li>
            <li>
              यह है कि कंपनी द्वारा संग्रहण से संबंधित जो सुविधाएं जैसे कि
              एसएमएस, कॉलिंग, मेल, नोटिस प्रदान करने के लिए जो भी सर्विस चार्ज
              अलग से मांगा जाएगा, वह हम प्रदान करेंगे।
            </li>
            <li>
              यह है कि इस अनुबंध के अनुसार गारंटर की पूरी जिम्मेदारी और
              उत्तरदायित्व है। अगर भविष्य में आवेदक के साथ कोई आकस्मिक घटना,
              मृत्यु, दिवालिया या गंभीर बीमारी हो जाती है, तो संपूर्ण लोन का
              भुगतान गारंटर द्वारा किया जाएगा।
            </li>
            <li>
              यह है कि हम/मैं द्वारा कंपनी को दी गई संपूर्ण जानकारी मौखिक रूप से
              प्रदान की गई है, जो कि सत्य व सही है। इसमें किसी भी प्रकार का कोई
              झूठा तथ्य नहीं बताया गया है। यदि कोई जानकारी झूठी पाई जाती है, तो
              कंपनी हमारे विरुद्ध कानूनी कार्रवाई करने का अधिकार रखेगी। मैं/हम
              ने लोन के बारे में समस्त जानकारी जैसे ब्याज, किस्तों की राशि,
              किस्तों का समय और अदायगी का समय अच्छी तरह से समझ लिया है। कंपनी के
              कर्मचारी द्वारा मुझे संपूर्ण जानकारी दे दी गई है। उक्त सभी नियमों
              व शर्तों के लिए मैं/हम पूरी तरह तैयार हैं। अगर मैं/हम किस्त देने
              में देरी करते हैं, तो उस पर लगने वाला अतिरिक्त भार, लीगल खर्च,
              पेपर खर्च, विजिट खर्च और अन्य खर्च जो भी भविष्य में आएगा, वह हमारे
              द्वारा प्रदान किया जाएगा।
            </li>
            <li>
              यह है कि मैं/हम प्रस्तुत किए गए समस्त दस्तावेज जैसे कि आधार कार्ड,
              पैन कार्ड, राशन कार्ड, ड्राइविंग लाइसेंस, बैंक की पासबुक और फोटो
              के साथ किसी भी तरह की छेड़छाड़ या कोई भी फर्जी दस्तावेज प्रस्तुत
              नहीं किए गए हैं। निकट भविष्य में यदि किसी भी दस्तावेज के फर्जी पाए
              जाने पर हमारे खिलाफ 420 का मुकदमा कंपनी द्वारा किया जा सकता है, इस
              बात से हम पहले से अवगत हैं।
            </li>
            <li>
              यह है कि हम/मैं अपनी किस्तों का भुगतान
              दैनिक/साप्ताहिक/द्विसाप्ताहिक/मासिक रूप में करना चाहते हैं।
            </li>
            <li>
              यह है कि इस ऋण के भुगतान और उसके बकाया या किसी भी अंश की स्वीकृति
              या किसी भी अन्य विशेष कार्य हेतु जब भी कंपनी द्वारा बुलाया जाएगा,
              मैं/हम समय पर उपस्थित हो जाएंगे।
            </li>
            <li>
              यह है कि मेरी सालाना आय ₹300000 से कम है। उक्त एग्रीमेंट या लोन को
              करते समय हमने किसी भी प्रकार का कोई नशा नहीं किया हुआ है। हम पूरे
              होशो हवास में यह एग्रीमेंट कर रहे हैं।
            </li>
            <li>
              हम यह वचनबद्ध बयान करते हैं कि भविष्य में यदि हमारे द्वारा किस्त
              समय पर नहीं जमा होगी, तो कंपनी हमारे विरुद्ध कानूनी कार्यवाही करने
              का अधिकार रखेगी। इससे मुझे कोई आपत्ति नहीं है।
            </li>
            <li>
              यदि हम अपनी किस्त समय से नहीं जमा करते हैं या डिफॉल्ट होने की
              स्थिति में, कंपनी सिबिल पर हमारा रिकॉर्ड दर्ज करने का अधिकार
              रखेगी।
            </li>
          </ol>
        </div>

        {/* New page break */}
        <div className="page-break"></div>

        <div className="section">
          <h2 className="title">Terms and Conditions</h2>

          <ol>
            <li>
              All the above parties agree to all the following terms and
              conditions: the borrower will use this amount as per the rules of
              microfinance and will not use this amount or any part of it for
              any other purpose. We also agree that if the company finds any
              reason to believe that the terms have not been complied with, the
              company has the right to withdraw this loan amount or part thereof
              without any concession.
            </li>
            <li>
              My guarantor and I have not been involved in any illegal acts in
              the past, nor is there any criminal case pending against us in any
              court, nor is any FIR registered against us in any police station.
            </li>
            <li>
              I/We are not burdened with any kind of loan from any financial
              institution, bank, or finance company, nor have I/We been declared
              a defaulter by any financial institution, bank, or finance
              company.
            </li>
            <li>
              I/We are fully capable of paying the entire installments of the
              said loan and I/We shall not delay the payment under any
              circumstances.
            </li>
            <li>
              I/We agree that whichever employee of the company comes to collect
              the installment amount, I will treat them politely and will not
              use any objectionable language, nor will I attempt to harm or beat
              them in any way, nor will I make false allegations or use abusive
              language. If any abusive behavior is exhibited by us, the company
              will be free to take legal action against us.
            </li>
            <li>
              I/We acknowledge that the company will not be responsible for
              sending notices, reminders, or electronic notifications (phone,
              etc.) for the payment of my installments. The entire
              responsibility related to the due date of payment of the loan will
              be mine/ours.
            </li>
            <li>
              If the Borrower(s) or the Guarantor(s) change their business, job,
              domicile, mobile number, or email ID, they shall give prior
              written notice to the company.
            </li>
            <li>
              I/We undertake that if future installments are paid by us through
              cheque, we will not stop payment of the cheques given to the bank,
              nor report the loss of the said cheque to the police station, nor
              close that account. If we do so, the company will have the right
              to take legal action against us.
            </li>
            <li>
              During the Company's storage-related facilities, such as SMS, we
              will provide the additional service charge required for providing
              the calling and email notice.
            </li>
            <li>
              The guarantor is fully responsible and liable as per this
              agreement. If any accidental event, death, insolvency, or serious
              illness occurs to the applicant in the future, the entire loan
              shall be paid by the guarantor.
            </li>
            <li>
              All the information provided by me/us to the company has been
              given truthfully and accurately, with no false statements. If any
              information is found to be false, the company has the right to
              take legal action against us. I/We have received complete
              information about the loan, such as the amount of interest,
              installment, and the time of repayment, from the company’s
              employee. We are fully prepared for all the above terms and
              conditions, and if we delay in paying the installments, we will
              bear the additional burden of legal expenses and other costs that
              may arise in the future.
            </li>
            <li>
              All the documents submitted by me/us, such as Aadhar card, PAN
              card, ration card, driving license, bank passbook, and photo, have
              not been tampered with, nor have we submitted any fake documents.
              If any document is found to be forged, we are aware that the
              company can sue us under Section 420.
            </li>
            <li>
              I/We wish to pay our installments daily/weekly/biweekly/monthly
              (as agreed upon).
            </li>
            <li>
              I/We shall be present in time as and when called for by the
              company for the payment of this loan, acceptance of arrears or any
              part thereof, or for any other specified purpose.
            </li>
            <li>
              My annual income is less than 300,000/-. We have not consumed any
              intoxicants while entering into this agreement or loan, and we are
              doing so in full consciousness.
            </li>
            <li>
              I/We undertake that in the future, if I/We do not deposit the
              installments on time, the company will have the right to take
              legal action against me/us. I/We have no objection to this.
            </li>
            <li>
              If I/We do not deposit my/our installment on time or in case of
              default, the company will have the right to report my/our record
              to CIBIL.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

LoanAgreement.prototype = {
  data: Proptypes.any,
  printRef: Proptypes.any,
};
