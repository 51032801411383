import React from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NotFoundImg from "./assets/images/notfound.png";
import companylogo from "./assets/images/companylogo.svg";

const NotFoundPage = () => {
  const history = useHistory();

  return (
    <>
      <Typography variant="h6" className='title' textAlign={"left"}marginLeft={2} padding={1} component={'div'}>
        <img src={companylogo} alt="logo" />
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "90vh",
          width: "100%",
          backgroundColor: "#f8f9fa",
          fontFamily: "Lato, sans-serif",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            paddingLeft={20}
            paddingRight={10}
            style={{ margin: "auto 0px" }}
          >
            <Stack spacing={5}>
              <h1
                style={{
                  fontFamily: "Lato",
                  fontWeight: "bold",
                  fontSize: "35px",
                }}
              >
                Something is not right...
              </h1>
              <p
                style={{
                  fontFamily: "Lato",
                  fontWeight: "bold",
                  fontSize: "20px",
                  color: "#868e96",
                }}
              >
                Page you are trying to open does not exist, or You may not have
                access of this page. If you think this is an error contact
                support.
              </p>
              <Button
                className="muiCustomButton"
                variant="outlined"
                onClick={() => history.push("/dashboard")}
                style={{ maxWidth: "300px", width: "200px" }}
              >
                Go To Dashboard
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={6} paddingLeft={10} paddingRight={20}>
            <img
              src={NotFoundImg}
              loading="lazy"
              width={"500px"}
              height={"400px"}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default NotFoundPage;
