import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import axios from "axios";
import { BaseUrl } from "../Environment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  IconButton,
  Popper,
  Typography,
  Paper,
  Button,
  Fade,
  CircularProgress,
  Alert,
  Collapse,
  AlertTitle
} from "@mui/material";
import { DeleteOutline, Close } from "@material-ui/icons";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { canAddBorrowers } from "../utilsFunctions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: "center",
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    backgroundColor: "#fff",
    border: "1px solid gray",
    borderRadius: "5px",
    padding: "5px",
    margin: "10px",
  },
  icon: {
    fontSize: 48,
    marginBottom: theme.spacing(2),
  },
}));

const CsvImportExport = () => {
  const history = useHistory();

  let mainData = [];
  const country = JSON.parse(localStorage.getItem("country"));
  const invalidRowsFile = [];
  const duplicateEntriesInFile = [];
  const seenEntriesNameNumber = {};
  const seenEntriesPan = {};
  const seenEntriesAadhar = {};
  const seenEntriesEmail = {};
  const seenEntriesCustId = {};

  const classes = useStyles();
  const [customers, setCustomers] = useState([]);
  const [file, setFile] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState([]);
  const [alertHeaderText, setAlertHeaderText] = useState(``);
  const [borrowersDataLength, setBorrowersDataLength] = useState();

  const fetchBorrowersData = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    const companyId = localStorage.getItem("loggedinUserCompany");

    const headers = {
      Authorization: "Bearer " + token,
    };

    try {
      const resp = await axios.get(BaseUrl + `/customer/getAllCustomers?name=&companyId=${companyId}`, {
        headers: headers,
      });

      if (resp?.status == 200 && resp?.data) {
        setBorrowersDataLength(resp?.data?.length);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBorrowersData();
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleImport = () => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(firstSheet);

        jsonData.forEach((row, index) => {
          const { firstName, lastName, pan, aadhar, email, cust_id, phone } = row;

          // Check for required fields
          if (!firstName || !lastName || !phone || !cust_id) {
            invalidRowsFile.push({
              rowNumber: index + 1,
              errorMessage: `These fields are mandatory(firstName, lastName, phone, cust_id).`,
            });
          } else {
            // Check for duplicate based on firstName, lastName, and phone
            const entryKeyBasic = `${firstName}${lastName}${phone}`;
            if (seenEntriesNameNumber[entryKeyBasic]) {
              duplicateEntriesInFile.push({
                rowNumber: index + 1,
                errorMessage: `Duplicate entry found for combination of firstName, lastName, and phone.`,
              });
            } else {
              seenEntriesNameNumber[entryKeyBasic] = true;
              mainData.push(row);
            }

            // Check for duplicate based on pan
            if (pan && seenEntriesPan[pan]) {
              duplicateEntriesInFile.push({
                rowNumber: index + 1,
                errorMessage: `Duplicate Pan Number: ${pan}`,
              });
            } else if (pan) {
              seenEntriesPan[pan] = true;
            }

            // Check for duplicate based on pan
            if (cust_id && seenEntriesCustId[cust_id]) {
              duplicateEntriesInFile.push({
                rowNumber: index + 1,
                errorMessage: `Duplicate Cust Id: ${cust_id}`,
              });
            } else if (cust_id) {
              seenEntriesCustId[cust_id] = true;
            }

            // Check for duplicate based on aadhar
            if (aadhar && seenEntriesAadhar[aadhar]) {
              duplicateEntriesInFile.push({
                rowNumber: index + 1,
                errorMessage: `Duplicate Aadhar Number: ${aadhar}`,
              });
            } else if (aadhar) {
              seenEntriesAadhar[aadhar] = true;
            }

            // Check for duplicate based on email
            if (email && seenEntriesEmail[email]) {
              duplicateEntriesInFile.push({
                rowNumber: index + 1,
                errorMessage: `Duplicate Email Address: ${email}`,
              });
            } else if (email) {
              seenEntriesEmail[email] = true;
            }
          }
        });

        // Create Excel workbook for invalid rows
        if (invalidRowsFile.length > 0) {
          // // Code to generate and download Excel file for invalid rows
          // const errorWorkbook = XLSX.utils.book_new();
          // const errorSheet = XLSX.utils.aoa_to_sheet([
          //   ["Row Number", "Error Message"],
          //   ...invalidRowsFile.map(({ rowNumber, errorMessage }) => [
          //     rowNumber,
          //     errorMessage,
          //   ]),
          // ]);
          // XLSX.utils.book_append_sheet(
          //   errorWorkbook,
          //   errorSheet,
          //   "Validation Errors"
          // );

          // // Generate and download the Excel file
          // const wbout = XLSX.write(errorWorkbook, {
          //   bookType: "xlsx",
          //   type: "array",
          // });
          // const blob = new Blob([wbout], { type: "application/octet-stream" });
          // const fileName = "CustomerValidationErrors.xlsx";

          // saveAs(blob, fileName);
          setAlertHeaderText(`Some of the rows data are invalid in uploaded file. Please check the explanation of the error.`);
          clearUploadedFile();
          setAlertData(invalidRowsFile);
          setShowAlert(true);
        }

        // Create Excel workbook for duplicate entries
        if (duplicateEntriesInFile.length > 0) {
          // const duplicateWorkbook = XLSX.utils.book_new();
          // const duplicateSheet = XLSX.utils.aoa_to_sheet([
          //   ["Row Number", "Error Message"],
          //   ...duplicateEntriesInFile.map(({ rowNumber, errorMessage }) => [
          //     rowNumber,
          //     errorMessage,
          //   ]),
          // ]);
          // XLSX.utils.book_append_sheet(
          //   duplicateWorkbook,
          //   duplicateSheet,
          //   "Duplicate Entries"
          // );

          // // Generate and download the Excel file for duplicates
          // const wboutDuplicate = XLSX.write(duplicateWorkbook, {
          //   bookType: "xlsx",
          //   type: "array",
          // });
          // const blobDuplicate = new Blob([wboutDuplicate], {
          //   type: "application/octet-stream",
          // });
          // const fileNameDuplicate = "DuplicateEntriesInFile.xlsx";
          // toast.error(
          //   `The file ${file.name} has been validated for validation errors. Please resolve the issues in the ${fileNameDuplicate} in Downloads directory.`,
          //   {
          //     position: "top-center",
          //     autoClose: 10000,
          //     hideProgressBar: true,
          //     closeOnClick: true,
          //     pauseOnHover: false,
          //     draggable: true,
          //   }
          // );
          // saveAs(blobDuplicate, fileNameDuplicate);
          setAlertHeaderText(`Some of the rows data are duplicate in uploaded file. Please check the explanation of the error.`);
          clearUploadedFile();
          setAlertData(duplicateEntriesInFile);
          setShowAlert(true);
        }

        if (invalidRowsFile.length == 0 && duplicateEntriesInFile.length == 0) {
          const token = JSON.parse(localStorage.getItem("token"));
          const companyId = localStorage.getItem("loggedinUserCompany");

          const headers = {
            Authorization: "Bearer " + token,
          };

          axios
            .get(
              `${BaseUrl}/customer/getAllCustomers?name=&companyId=${companyId}`,
              {
                headers,
              }
            )
            .then((response) => {
              const dbCustomers = response.data;
              mainData = [...new Set(mainData)];

              // Compare CSV data with existing customer data
              const duplicateCustomers = findDuplicates(mainData, dbCustomers);

              if (duplicateCustomers?.length > 0) {
                // const duplicateWorkbook = XLSX.utils.book_new();
                // const duplicateSheet = XLSX.utils.aoa_to_sheet([
                //   ["Row Number", "Duplicate Column", "Error Message"],
                //   ...duplicateCustomers.map((data) => [
                //     data?.rowNumber,
                //     data?.duplicateColumn,
                //     `Column(${data?.duplicateColumn}) value already exists in the database. Please validate the file(${file.name})`,
                //   ]),
                // ]);
                // XLSX.utils.book_append_sheet(
                //   duplicateWorkbook,
                //   duplicateSheet,
                //   "Duplicate Entries in DB"
                // );

                // // Generate and download the Excel file for duplicates
                // const wboutDuplicate = XLSX.write(duplicateWorkbook, {
                //   bookType: "xlsx",
                //   type: "array",
                // });
                // const blobDuplicate = new Blob([wboutDuplicate], {
                //   type: "application/octet-stream",
                // });
                // const fileNameDuplicate = "DuplicateEntriesInDB.xlsx";
                // toast.error(
                //   `The file ${file.name} has been validated for duplicates with the DataBase. Please resolve the duplicates in the ${fileNameDuplicate} in Downloads directory.`,
                //   {
                //     position: "top-center",
                //     autoClose: 10000,
                //     hideProgressBar: true,
                //     closeOnClick: true,
                //     pauseOnHover: false,
                //     draggable: true,
                //   }
                // );
                // saveAs(blobDuplicate, fileNameDuplicate);
                setAlertHeaderText(`Some of the file data and database data are duplicate in uploaded file. Please check the explanation of the error.`);
                clearUploadedFile();
                setAlertData(duplicateCustomers);
                setShowAlert(true);
              } else {
                setCustomers(mainData);
              }
            })
            .catch((error) => {
              console.error("Error fetching customer data:", error);
            });
        }

        // Proceed with further processing or final actions (if needed)
      };
      reader.readAsArrayBuffer(file);
    }
  };

  // Function to find duplicates
  const findDuplicates = (csvData, dbData) => {
    const duplicates = [];

    csvData.forEach((csvCustomer, i) => {
      dbData.forEach((dbCustomer) => {
        let duplicateColumn = null;

        if (csvCustomer.cust_id == dbCustomer.cust_id) {
          duplicateColumn = "cust_id";
        } else if (
          csvCustomer.firstName == dbCustomer.firstName &&
          csvCustomer.lastName == dbCustomer.lastName &&
          `+${country?.code}${csvCustomer.phone}` == dbCustomer.phone
        ) {
          duplicateColumn = "firstName,lastName,phone";
        } else if (csvCustomer.pan && csvCustomer.pan == dbCustomer.pan) {
          duplicateColumn = "pan";
        } else if (csvCustomer.email && csvCustomer.email == dbCustomer.email) {
          duplicateColumn = "email";
        } else if (
          csvCustomer.aadhar &&
          csvCustomer.aadhar == dbCustomer.aadhar
        ) {
          duplicateColumn = "aadhar";
        }

        if (duplicateColumn) {
          duplicates.push({
            // csvCustomer,
            rowNumber: i + 1,
            duplicateColumn,
          });
        }
      });
    });

    return duplicates;
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const postCustomer = async (data) => {
    const alldetails = {
      customer: {
        firstName: data?.firstName ?? "",
        lastName: data?.lastName ?? "",
        cust_id: data?.cust_id ?? "",
        areaLine: data?.areaLine ?? "",
        gender: data?.gender ?? "",
        maritalStatus: "",
        email: data?.email ?? "",
        dob: new Date(),
        fatherName: data?.fatherName ?? "",
        motherName: data?.motherName ?? "",
        annualIncome: data?.annualIncome ?? "",
        incomeSource: data?.incomeSource ?? "",
        dependentName: data?.dependentname ?? "",
        phone: `+${country?.code}${data?.phone}` ?? "",
        aadhar: data?.aadhar ?? "",
        pan: data?.pan ?? "",
        createdByUserId: localStorage.getItem("userid") ?? "",
        updatedByUserId: localStorage.getItem("userid") ?? "",
        verified: true,
        companyId: localStorage.getItem("loggedinUserCompany") ?? "",
      },
      bankDetails: {
        bankName: data?.bankname ?? "",
        ifsc: data?.bankifsc ?? "",
        accountType: data?.bankaccounttype ?? "",
        accountNumber: data?.bankaccountnum ?? "",
        createdByUserId: localStorage.getItem("userid") ?? "",
        updatedByUserId: localStorage.getItem("userid") ?? "",
        companyId: localStorage.getItem("loggedinUserCompany") ?? "",
      },
      reference: {
        referenceNumber1: data?.refcontactnum1 ?? "",
        referenceNumber2: data?.refcontactnum2 ?? "",
        referenceName1: data?.refname1 ?? "",
        referenceName2: data?.refname2 ?? "",
        createdByUserId: localStorage.getItem("userid") ?? "",
        updatedByUserId: localStorage.getItem("userid") ?? "",
        companyId: localStorage.getItem("loggedinUserCompany") ?? "",
      },
      address: [
        {
          AddressLine: data?.homeAddressLine ?? "",
          AddressStreet: data?.homeAddressStreet ?? "",
          AddressLandmark: data?.homeAddressLandmark ?? "",
          AddressCity: data?.homeAddressCity ?? "",
          AddressPincode: parseInt(data?.homeAddressPincode) ?? 0,
          AddressState: data?.homeAddressState ?? "",
          AddressType: "Home" ?? "",
          createdByUserId: localStorage.getItem("userid") ?? "",
          updatedByUserId: localStorage.getItem("userid") ?? "",
          companyId: localStorage.getItem("loggedinUserCompany") ?? "",
        },
        {
          AddressLine: data?.OfficeAddressLine ?? "",
          AddressStreet: data?.OfficeAddressStreet ?? "",
          AddressCity: data?.OfficeAddressCity ?? "",
          AddressLandmark: data?.OfficeAddressLandmark ?? "",
          AddressPincode: parseInt(data?.OfficeAddressPincode) ?? 0,
          AddressState: data?.OfficeAddressState ?? "",
          AddressType: "Office" ?? "",
          createdByUserId: localStorage.getItem("userid") ?? "",
          updatedByUserId: localStorage.getItem("userid") ?? "",
          companyId: localStorage.getItem("loggedinUserCompany") ?? "",
        },
      ],
      collections: {
        collectionTime: data?.collectionTime ?? "",
        collectonPlace: data?.selectedaddress ?? "",
        createdByUserId: localStorage.getItem("userid") ?? "",
        updatedByUserId: localStorage.getItem("userid") ?? "",
        companyId: localStorage.getItem("loggedinUserCompany") ?? "",
      },
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    };

    let formData = new FormData();
    formData.append("alldetails", JSON.stringify(alldetails));

    try {
      return await axios.post(BaseUrl + "/customer/postCustomer", formData, { headers: headers})
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (customers?.length > 0) {
      setLoading(true);
  
      const processCustomers = async () => {
        var errorExits = false;
        var toastMsg = "";

        for (const cust of customers) {
          try {
            // Wait for each loan to be posted sequentially
            const response = await postCustomer(cust);
            console.log("response: " + JSON.stringify(response));
            
            if (!response?.data?.customerId) {
              toastMsg = response.data?.message;
              errorExits = true;
              break; // Stop processing if a condition violation occurs
            }
          } catch (error) {
            console.error("Error adding borrower:", error);
            break; // Optionally stop on error
          }
        }
  
        if (errorExits) {
          toast.error(toastMsg, {
              position: "top-center",
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );
        } else {
          toast.success("Borrower Added Successfully.", {
              position: "top-center",
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );
        }
        setLoading(false);
        toastMsg = "";
        errorExits = false;
        history.push("/allnewcustomers");
      };
  
      processCustomers();
    }
  }, [customers]);

  const clearUploadedFile = () => {
    setFile(null);
    // If you need to clear the input field, you can do that too
    const fileInput = document.getElementById("contained-button-file");
    if (fileInput) {
      fileInput.value = null;
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  return (
    <div className={classes.root}>
      {loading ? <CircularProgress /> :
      <>
      {showAlert && (
        <Collapse in={showAlert}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowAlert(false);
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            <AlertTitle>
              {alertHeaderText}
            </AlertTitle>
            {alertData?.map((data, i) => (
              data?.rowNumber == 0 ? <strong>{data?.errorMessage}</strong> :
              <div key={i}>
                <strong>Row Number</strong>:{" "}
                <span>{data?.rowNumber}</span>,{" "}
                <strong>Error Message</strong>:{" "}
                <span>{data?.duplicateColumn ? `Duplicate column '${data?.duplicateColumn}' was found.` : data?.errorMessage }</span>
                <br />
              </div>
            ))}
          </Alert>
        </Collapse>
      )}
      <Popper
        // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
        sx={{ zIndex: 1200, width: "100%" }}
        open={open}
        onClick={() => setOpen(!open)}
        anchorEl={anchorEl}
        placement={"bottom"}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">Important points to note</Typography>
              <Typography sx={{ pl: 1 }}>
                1. Please add your data to the above template for loans and
                customers...
              </Typography>
              <Typography sx={{ pl: 1 }}>
                2. First Upload Customers data followed by loans data.
              </Typography>
              <Typography sx={{ pl: 1 }}>
                3. The following validations will be done by the app
                <Typography sx={{ pl: 1 }}>
                  a.Check for duplicates in borrowers within the Excel and get
                  downloaded as duplicates.xlsx File.
                  <br />
                  b.Check for duplicates in borrowers in Excel with DataBase and
                  get downloaded as duplicatesinDB File.
                  <br />
                  c. Amounttobe Collected should be greater than or equal to the
                  Lent Amount. <br />
                  d.Collection Agent First name and Last Name should exactly
                  match with the name in the User Management Screen.
                  <br />
                  e.While adding customers please provide a unique CustomerId to
                  each of your Borrowers and give respective customerId while
                  creating loans for that Borrower. <br />
                  f. When not paid, If you are planning to Accumulate the
                  current installment amount to the next installment,we have
                  disabled Auto Update for this.Please provide Auto Update as
                  No,if Accumulate is Yes.
                </Typography>
              </Typography>
              <Typography sx={{ pl: 1 }}>
                4. The above validations c,d,e and f will be downloaded as
                LoansValidationErrors.xlsx
              </Typography>
              <Typography sx={{ pl: 1 }}>
                5. Please recheck the excel with the above error files and
                import the entire file again.
              </Typography>
              <Typography sx={{ pl: 1 }}>
                6. Unless all the validation issues are resolved,the data will
                not be added to database.
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Paper
        className={classes.paper}
        elevation={3}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <Typography component="p">
          Please read the{" "}
          <span
            onClick={handleClick}
            style={{
              color: "blue",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            instructions
          </span>{" "}
          before proceeding to import file
        </Typography>
        <Typography mb={3} alignSelf={"flex-end"} component="a">
          <a
            href="https://drive.google.com/uc?export=download&id=1OGItFO04a2m2H5Y_UnCLIX_dN0y00i7a"
            target="_blank"
            style={{
              color: "blue",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Sample template
          </a>{" "}
        </Typography>

        <Typography variant="h5" component="h2">
          Drag and drop your file here or
        </Typography>
        <input
          accept=".xlsx,.xls"
          className={classes.input}
          id="contained-button-file"
          type="file"
          onChange={handleFileChange}
        />
        <Button
          variant="contained"
          color="primary"
          component="span"
          startIcon={<CloudUploadIcon />}
          onClick={() => {
            const loggedinUser = JSON.parse(localStorage.getItem("loggedinUser"));
            const planId = loggedinUser?.company?.planId;
            const planEndDate = loggedinUser?.company?.planEndDate;
            const maxBorrowersCanBeAdded = planId == 0 ? 10 : planId == 1 ? 15 : planId == 2 ? 1000 : ""
            const currentlyActiveBorrowers = borrowersDataLength;
            if(canAddBorrowers(planId, planEndDate, maxBorrowersCanBeAdded, currentlyActiveBorrowers)){
              handleImport()
            } else {
              setAlertHeaderText(`Max limit reached`);
              clearUploadedFile();
              setAlertData([{rowNumber: 0, errorMessage: "You can't import borrowers because, You have reached at maximum limit of adding borrowers."}]);
              setShowAlert(true);
            }
          }}
          style={{ marginBlock: 10 }}
        >
          Upload File
        </Button>
        {file && (
          <Typography variant="body1" component="p">
            Uploaded File: {file.name}
          </Typography>
        )}
        {file && (
          <IconButton aria-label="delete" onClick={clearUploadedFile}>
            <DeleteOutline />
          </IconButton>
        )}
      </Paper>
      </>}
    </div>
  );
};

export default CsvImportExport;
