import React, { useState, useEffect } from "react";
import {
  Stack,
  Typography,
  Box,
  Button,
  TextField,
  Grid,
  Snackbar,
  Alert,
  Link,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Select from "react-select";
import { companylogo } from "../../assets/images";
import {
  getAllAssetTypesDataAPI,
  addAssetTypeAPI,
  getAllBankAccountsDataAPI,
  addBankAccountAPI,
  updateAssetAPI,
  addAssetAPI,
  getAssetDetailsDataAPI,
} from "../../service/service";
import Sidebar from "../sidebar/sidebar";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import "./AssetManagement.scss";
import moment from "moment";

const AddUpdateAsset = () => {
  const history = useHistory();
  const params = useParams();
  const loggedinUser = JSON.parse(localStorage.getItem("loggedinUser"));

  const assetStaticTypes = [
    { label: "Brand", value: "S1" },
    { label: "Building", value: "S2" },
    { label: "Copyright", value: "S3" },
    { label: "Equipments", value: "S4" },
    { label: "Furniture", value: "S5" },
    { label: "Goodwill", value: "S6" },
    { label: "Land", value: "S7" },
    { label: "Machinery", value: "S8" },
    { label: "Patents", value: "S9" },
    { label: "Plant", value: "S10" },
    { label: "Trademark", value: "S11" },
  ];

  const optionsOfAccount = [{ label: "Cash", value: "0000" }];

  const filterArrays = (apiArr, staticArr) => {
    // Combine both arrays
    const combinedArr = [...apiArr, ...staticArr];

    // Group by label
    const groupedByLabel = combinedArr.reduce((acc, current) => {
      acc[current.label] = acc[current.label] || [];
      acc[current.label].push(current);
      return acc;
    }, {});

    // Filter groups to only include API data if both API and static data are present
    const filteredArr = Object.values(groupedByLabel).reduce((acc, group) => {
      const hasApiData = group.some((item) => apiArr.includes(item));
      const apiGroup = group.filter((item) => apiArr.includes(item));
      const staticGroup = group.filter((item) => staticArr.includes(item));

      if (hasApiData) {
        // If API data is present, use only API data
        acc.push(...apiGroup);
      } else {
        // If no API data, use static data
        acc.push(...staticGroup);
      }

      return acc;
    }, []);

    return filteredArr;
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [toastMsg, setToastMsg] = useState({
    msg: "",
    time: 2000,
    variant: "success",
  });

  const [payload, setPayload] = useState({
    assetTypeId: "",
    isPurchase: true,
    date: moment().format("YYYY-MM-DD"),
    value: "",
    srcAccId: "",
    isSold: false,
    soldDate: moment().format("YYYY-MM-DD"),
    soldValue: "",
    destAccId: "",
    replacementValue: "",
    serialNumber: "",
    boughtFrom: "",
    description: "",
    invoiceReceipt: "",
  });

  const [errors, setErrors] = useState({
    assetTypeId: "",
    date: "",
    value: "",
    srcAccId: "",
    soldDate: "",
    soldValue: "",
    destAccId: "",
    bookVal: "",
    dateVal: "",
  });

  const [labelOfType, setLabelOfType] = useState("");
  const [labelOfBank, setLabelOfBank] = useState("");
  const [assetTypeData, setAssetTypeData] = useState([]);
  const [bankAccountData, setBankAccountData] = useState([]);
  const [rows, setRows] = useState([
    { assetBookDateValueId: null, book: "", date: moment().format("YYYY-MM-DD") },
  ]);

  const fetchAssetTypesData = async () => {
    try {
      const companyId = loggedinUser?.companyId;

      const AssetTypesRes = await getAllAssetTypesDataAPI(companyId);

      const valueLabelData = AssetTypesRes?.data?.map((item) => ({
        label: item.title,
        value: item.assetTypeId,
      }));

      if (AssetTypesRes?.status == 200 && AssetTypesRes?.data) {
        setAssetTypeData(filterArrays(valueLabelData, assetStaticTypes));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBAData = async () => {
    try {
      const companyId = loggedinUser?.companyId;

      const data = {
        companyId,
        searchQuery: "",
      };

      const resp = await getAllBankAccountsDataAPI(data);

      if (resp?.data?.length > 0) {
        setBankAccountData(resp?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAssetDetails = async () => {
    try {
      const resp = await getAssetDetailsDataAPI(
        localStorage.getItem("assetId")
      );

      if (resp?.data) {
        const {
          assetTypeId,
          isPurchase,
          date,
          value,
          srcAccId,
          isSold,
          soldDate,
          soldValue,
          destAccId,
          serialNumber,
          boughtFrom,
          replacementValue,
          description,
          invoiceReceipt,
          assetBookDateValues,
        } = resp?.data;
        setPayload({
          assetTypeId,
          isPurchase,
          date: moment(date).format("YYYY-MM-DD"),
          value,
          srcAccId: srcAccId ? srcAccId : "",
          isSold,
          soldDate: moment(soldDate).format("YYYY-MM-DD"),
          soldValue,
          destAccId: destAccId ? srcAccId : "",
          serialNumber,
          boughtFrom,
          replacementValue,
          description,
          invoiceReceipt,
        });
        if (assetBookDateValues?.length > 0) {
          setRows(
            assetBookDateValues?.map((item) => ({
              assetBookDateValueId: item?.assetBookDateValueId,
              book: parseInt(item.book),
              date: moment(item.date).format("YYYY-MM-DD"),
            }))
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBAData();
    fetchAssetTypesData();
    if (localStorage.getItem("isAssetUpdate")) {
      getAssetDetails();
    }
  }, []);

  const AccountsSelectData = [
    ...bankAccountData?.map((item) => ({
      label: item.bankName,
      value: item.accountId,
    })),
    ...optionsOfAccount,
  ];

  const uniqueAccounts = AccountsSelectData.reduce((acc, item) => {
    if (
      !acc.has(item.label) ||
      (acc.has(item.label) && acc.get(item.label).value == "0000")
    ) {
      acc.set(item.label, item);
    }
    return acc;
  }, new Map());

  const filteredDataAccounts = Array.from(uniqueAccounts.values());

  const handleChange = (field, value) => {
    setPayload((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSaveAsset = async () => {
    const userId = loggedinUser?.userId;
    const companyId = loggedinUser?.companyId;
    const {
      assetTypeId,
      isPurchase,
      date,
      value,
      srcAccId,
      isSold,
      soldDate,
      soldValue,
      destAccId,
      serialNumber,
      boughtFrom,
      description,
      invoiceReceipt,
      replacementValue
    } = payload;

    const newErrors = {
      assetTypeId: !payload.assetTypeId ? "This field is required" : "",
      date: !payload.date ? "This field is required" : "",
      value: !payload.value ? "This field is required" : "",
      srcAccId:
        payload.isPurchase && !payload.srcAccId ? "This field is required" : "",
      soldDate:
        payload.isSold && !payload.soldDate ? "This field is required" : "",
      soldValue:
        payload.isSold && !payload.soldValue ? "This field is required" : "",
      destAccId:
        payload.isSold && !payload.destAccId ? "This field is required" : "",
      bookVal: !rows[0].book ? "This field is required" : "",
      dateVal: !rows[0].date ? "This field is required" : "",
    };

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error != "");

    if (!hasErrors) {
      try {
        let catePayload, bankAccountPayload, cateRes, bankRes;

        if (labelOfType) {
          catePayload = {
            title: labelOfType,
            description: `Desc ${labelOfType}`,
            companyId,
            createdByUserId: userId,
            updatedByUserId: userId,
          };

          cateRes = await addAssetTypeAPI(catePayload);

          if (cateRes?.status == 200) {
            setLabelOfType("");
            handleChange("assetTypeId", cateRes.data?.assetTypeId);
          }
        }

        if (labelOfBank) {
          bankAccountPayload = {
            code: "0000",
            bankName: labelOfBank,
            companyId,
            createdByUserId: userId,
            updatedByUserId: userId,
          };

          bankRes = await addBankAccountAPI(bankAccountPayload);

          if (bankRes?.status == 200) {
            setLabelOfBank("");
            handleChange("srcAccId", bankRes.data.result?.accountId);
            handleChange("destAccId", bankRes.data.result?.accountId);
          }
        }

        let AssetPayload = new FormData();

        AssetPayload.append(
          "assetTypeId",
          cateRes?.data?.assetTypeId ? cateRes?.data?.assetTypeId : assetTypeId
        );
        AssetPayload.append("isPurchase", isPurchase);
        AssetPayload.append("date", date);
        AssetPayload.append("value", value);
        AssetPayload.append(
          "srcAccId",
          bankRes?.data?.result?.accountId
            ? bankRes?.data?.result?.accountId
            : srcAccId
        );
        AssetPayload.append("isSold", isSold);
        AssetPayload.append("soldDate", soldDate);
        AssetPayload.append("soldValue", soldValue);
        AssetPayload.append(
          "destAccId",
          bankRes?.data?.result?.accountId
            ? bankRes?.data?.result?.accountId
            : destAccId
        );
        AssetPayload.append("bookDate", JSON.stringify(rows));
        AssetPayload.append("serialNumber", serialNumber);
        AssetPayload.append("replacementValue", replacementValue);
        AssetPayload.append("boughtFrom", boughtFrom);
        AssetPayload.append("description", description);
        AssetPayload.append("invoiceReceipt", invoiceReceipt);

        if (localStorage.getItem("isAssetUpdate")) {
          AssetPayload.append("assetId", localStorage.getItem("assetId"));
        }
        AssetPayload.append("companyId", companyId);
        AssetPayload.append("createdByUserId", userId);
        AssetPayload.append("updatedByUserId", userId);

        const res = localStorage.getItem("isAssetUpdate")
          ? await updateAssetAPI(AssetPayload)
          : await addAssetAPI(AssetPayload);

        if (res?.status == 200) {
          setToastMsg({
            msg: `Asset ${
              localStorage.getItem("isAssetUpdate") ? "Updated" : "Added"
            } Successfully.`,
            time: 4000,
            variant: "success",
          });

          localStorage.removeItem("isAssetUpdate");
          localStorage.removeItem("assetId");

          setTimeout(() => {
            handleOpen();
          }, 500);
          setTimeout(() => {
            history.push("asset");
          }, 1000);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const commonGridStyles = { padding: "0 10px", marginTop: "20px" };
  const requiredLabel = <span style={{ color: "red", fontSize: 15 }}>*</span>;

  const CustomGridItem = ({ md, sm, xs, children }) => (
    <Grid item md={md} sm={sm} xs={xs} sx={commonGridStyles}>
      {children}
    </Grid>
  );

  const CustomTypography = ({ children, required }) => (
    <Typography variant="body2" fontFamily="monospace" fontSize={15}>
      {children}
      {required && requiredLabel}
    </Typography>
  );

  const FormSection = ({ title, children, required }) => (
    <Grid container alignItems="center" marginBottom={2}>
      <CustomGridItem md={3} sm={4} xs={12}>
        <CustomTypography required={required}>{title}</CustomTypography>
      </CustomGridItem>
      <CustomGridItem md={9} sm={8} xs={12}>
        {children}
      </CustomGridItem>
    </Grid>
  );

  const addRow = () => {
    setRows([...rows, { assetBookDateValueId: null, book: "", date: moment().format("YYYY-MM-DD") }]);
  };

  const deleteRow = (index) => {
    const newRows = rows.filter((_, i) => i != index);
    setRows(newRows);
  };

  const handleInputChange = (index, name, value) => {
    const newRows = rows.map((row, i) =>
      i == index ? { ...row, [name]: value } : row
    );
    setRows(newRows);
  };

  const handleSoldChange = (e) => {
    const value = e.target.value == "true";
    handleChange("isSold", value);
  };

  const handlePurchaseChange = (e) => {
    const value = e.target.value == "true";
    handleChange("isPurchase", value);
  };

  const handleFileChangeInvoiceReceipt = (e) => {
    const selectedFile = e.target.files[0];
    handleChange("invoiceReceipt", selectedFile);
  };

  const handleKeyDown = (event) => {
    event.preventDefault(); // Block any keyboard interaction
  };

  return (
    <>
      <Sidebar />

      <Snackbar
        open={open}
        autoHideDuration={toastMsg.time}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={toastMsg.variant}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastMsg.msg}
        </Alert>
      </Snackbar>

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"10px 100px"}
      >
        <img src={companylogo} alt="logo" />
      </Stack>

      <Stack
        className="assetmanagementpage"
        spacing={1}
        padding={"10px 20px 10px 100px"}
      >
        <Typography
          textAlign={"center"}
          variant="body2"
          marginBlock={1}
          fontSize={18}
          fontWeight={700}
          textTransform={"uppercase"}
        >
          Add/Update Asset
        </Typography>
        <Box sx={{ width: "98%", padding: "10px", backgroundColor: "#fff" }}>
          {/* Existing Sections */}
          <Grid container alignItems="center" marginBottom={2}>
            <CustomGridItem md={2} sm={3} xs={12}>
              <CustomTypography required>Asset Type</CustomTypography>
            </CustomGridItem>
            <CustomGridItem md={7} sm={5} xs={12}>
              <Select
                options={assetTypeData}
                value={assetTypeData?.find(
                  (item) => item.value == payload.assetTypeId
                )}
                onChange={(item) => {
                  if (typeof item?.value != "string") {
                    handleChange("assetTypeId", item?.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      assetTypeId: null,
                    }));
                  } else {
                    setLabelOfType(item?.label);
                    handleChange("assetTypeId", item?.value);

                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      assetTypeId: null,
                    }));
                  }
                }}
                placeholder="Income Type"
              />
              {errors.assetTypeId && (
                <Typography
                  variant="body1"
                  style={{ color: "red", fontSize: "10px" }}
                >
                  {errors.assetTypeId}
                </Typography>
              )}
            </CustomGridItem>
            <CustomGridItem md={3} sm={4} xs={12}>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  history.push("assettypes");
                }}
                style={{ fontSize: 15, cursor: "pointer" }}
                underline="none"
              >
                Add/Update Asset Types
              </Link>
            </CustomGridItem>
          </Grid>

          <Typography
            variant="body2"
            marginBlock={1}
            fontSize={15}
            fontWeight={700}
            color="red"
            fontFamily="monospace"
          >
            Purchase or Opening Date and Value:
          </Typography>

          <Box
            marginBlock={0.5}
            sx={{
              width: "95%",
              padding: "10px",
              backgroundColor: "#d2d6de",
              borderRadius: "5px",
            }}
          >
            <Typography variant="body2" fontSize={14} fontFamily="monospace">
              This is the value at which you acquired the asset either by
              purchasing it or from owner's equity. If you purchased the asset
              and it was too long ago, you can enter the opening date and value
              here as of start of current financial year.
            </Typography>
          </Box>

          <FormSection title="Are you entering the Purchase value or the Opening value?" required={true}>
            <RadioGroup
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={payload.isPurchase}
              onChange={handlePurchaseChange}
            >
              <Stack spacing={0.1}>
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Purchase Date and Value:"
                />
                <Typography
                  variant="body2"
                  fontFamily="sans-serif"
                  fontSize={13}
                >
                  The asset was purchased at the below Date and Value
                </Typography>
              </Stack>
              <Stack spacing={0.1}>
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Opening Date and Value:"
                />
                <Typography
                  variant="body2"
                  fontFamily="sans-serif"
                  fontSize={13}
                >
                  If you select this option, this asset will not be shown in
                  Accounting and no journal entry will be made
                </Typography>
              </Stack>
            </RadioGroup>
          </FormSection>

          <FormSection title="Purchase/Opening Date" required={true}>
            <input
              type="date"
              onKeyDown={handleKeyDown} // Block typing
              style={{
                borderWidth: "1px",
                borderColor: "lightgray",
                borderRadius: "4px",
                width: "95%",
                padding: "10px",
              }}
              value={payload.date}
              onChange={(e) => handleChange("date", e.target.value)}
            />
            {errors.date && (
              <Typography
                variant="body1"
                style={{ color: "red", fontSize: "10px" }}
              >
                {errors.date}
              </Typography>
            )}
          </FormSection>

          <FormSection title="Purchase/Opening Value"  required={true}>
            <TextField
              hiddenLabel
              value={payload.value}
              inputMode="numeric"
              type="number"
              name="value"
              id="value"
              placeholder="Enter Purchase/Opening Value"
              onChange={(e) => handleChange("value", e.target.value)}
              variant="outlined"
              fullWidth
            />
            {errors.value && (
              <Typography
                variant="body1"
                style={{ color: "red", fontSize: "10px" }}
              >
                {errors.value}
              </Typography>
            )}
          </FormSection>

          {payload.isPurchase && (
            <Grid container alignItems="center" marginBottom={2}>
              <CustomGridItem md={2} sm={3} xs={12}>
                <CustomTypography required>Accounts</CustomTypography>
              </CustomGridItem>
              <CustomGridItem md={7} sm={5} xs={12}>
                <Select
                  options={filteredDataAccounts}
                  value={
                    payload.srcAccId &&
                    filteredDataAccounts.find(
                      (item) => item.value == payload.srcAccId
                    )
                  }
                  onChange={(val) => {
                    if (val.value == "0000") {
                      setLabelOfBank(val?.label);
                      handleChange("srcAccId", val?.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        srcAccId: null,
                      }));
                    } else {
                      handleChange("srcAccId", val?.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        srcAccId: null,
                      }));
                    }
                  }}
                  placeholder="Select Accounts (Purchase)"
                />
                {payload.isPurchase && errors.srcAccId && (
                  <Typography
                    variant="body1"
                    style={{ color: "red", fontSize: "10px" }}
                  >
                    {errors.srcAccId}
                  </Typography>
                )}
              </CustomGridItem>
              <CustomGridItem md={3} sm={4} xs={12}>
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("bankaccountmanagement");
                  }}
                  style={{ fontSize: 15, cursor: "pointer" }}
                  underline="none"
                >
                  Add/Update Bank Accounts
                </Link>
              </CustomGridItem>
            </Grid>
          )}

          {/* New Sections */}
          <Typography
            variant="body2"
            marginBlock={1}
            fontSize={15}
            fontWeight={700}
            color="red"
            fontFamily="monospace"
          >
            Depreciation:
          </Typography>

          <Box
            marginBlock={0.5}
            sx={{
              width: "95%",
              padding: "10px",
              backgroundColor: "#d2d6de",
              borderRadius: "5px",
            }}
          >
            <Typography variant="body2" fontSize={14} fontFamily="monospace">
              For physical assets like equipment and vehicles, you can use the
              below rows to show the depreciated value over time. Any reduction
              in book value from the previous financial year will be shown as an
              Expense in the Profit and Loss report and the book value of the
              asset will be shown in the Balance Sheet report under Assets.
            </Typography>
          </Box>

          <Box sx={{ overflowX: "auto", marginBlock: 2 }}>
            <Grid container>
              <Grid item md={3} sm={4} xs={12} sx={commonGridStyles}>
                <CustomTypography required>Value</CustomTypography>
              </Grid>
              <Grid item md={9} sm={8} xs={12} sx={commonGridStyles}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Typography
                      variant="body2"
                      fontFamily="monospace"
                      fontSize={15}
                    >
                      #
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      fontFamily="monospace"
                      fontSize={15}
                    >
                      Book Date
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      fontFamily="monospace"
                      fontSize={15}
                    >
                      Book Value
                    </Typography>
                  </Grid>
                </Grid>
                {rows.map((row, index) => (
                  <Grid container spacing={2} key={index} alignItems="center">
                    <Grid item xs={2}>
                      <Typography
                        variant="body2"
                        fontFamily="monospace"
                        fontSize={15}
                      >
                        {index + 1}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        type="date"
                        onKeyDown={handleKeyDown} // Block typing
                        fullWidth
                        value={row.date}
                        name="date"
                        id="date"
                        onChange={(e) =>
                          handleInputChange(index, "date", e.target.value)
                        }
                        variant="outlined"
                      />
                      {errors.dateVal && (
                        <Typography
                          variant="body1"
                          style={{ color: "red", fontSize: "10px" }}
                        >
                          {errors.dateVal}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        type="number"
                        placeholder="Number or decimal only"
                        fullWidth
                        value={row.book}
                        name="book"
                        id="book"
                        onChange={(e) =>
                          handleInputChange(index, "book", e.target.value)
                        }
                        variant="outlined"
                      />
                      {errors.bookVal && (
                        <Typography
                          variant="body1"
                          style={{ color: "red", fontSize: "10px" }}
                        >
                          {errors.bookVal}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Stack direction={"row"} spacing={3} alignItems={"center"}>
              <Button
                color="error"
                variant="filled"
                onClick={() =>
                  rows?.length == 1 ? {} : deleteRow(rows?.length - 1)
                }
                style={{
                  width: "200px",
                  backgroundColor: "red",
                  color: "#fff",
                }}
              >
                Delete Row{" "}
              </Button>
              <Button
                className="GreenBTN"
                style={{ width: "200px" }}
                variant="filled"
                onClick={addRow}
              >
                Add Row
              </Button>
            </Stack>
          </Box>

          <Typography
            variant="body2"
            marginBlock={1}
            fontSize={15}
            fontWeight={700}
            color="red"
            fontFamily="monospace"
          >
            Sold Date and Value:
          </Typography>

          <Box
            marginBlock={0.5}
            sx={{
              width: "95%",
              padding: "10px",
              backgroundColor: "#d2d6de",
              borderRadius: "5px",
            }}
          >
            <Typography variant="body2" fontSize={14} fontFamily="monospace">
              If you have disposed off the asset, please enter the sold date and
              value. The difference between the below sold value and the above
              most recent book value will be shown in the Profit/Loss report.
            </Typography>
          </Box>

          <FormSection title="Has the Asset been Sold?" required={true}>
            <RadioGroup
              row
              aria-labelledby="sold-radio-buttons-group-label"
              name="sold-radio-buttons-group"
              value={payload.isSold}
              onChange={handleSoldChange}
            >
              <FormControlLabel value={false} control={<Radio />} label="No" />
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
            </RadioGroup>
          </FormSection>

          <FormSection title="Sold Date" required={true}>
            <input
              type="date"
              onKeyDown={handleKeyDown} // Block typing
              style={{
                borderWidth: "1px",
                borderColor: "lightgray",
                borderRadius: "4px",
                width: "95%",
                padding: "10px",
              }}
              disabled={!payload.isSold}
              value={payload.soldDate}
              onChange={(e) => handleChange("soldDate", e.target.value)}
            />
            {payload.isSold && errors.soldDate && (
              <Typography
                variant="body1"
                style={{ color: "red", fontSize: "10px" }}
              >
                {errors.soldDate}
              </Typography>
            )}
          </FormSection>

          <FormSection title="Sold Value" required={true}>
            <TextField
              hiddenLabel
              value={payload.soldValue}
              inputMode="numeric"
              type="number"
              name="soldValue"
              id="soldValue"
              placeholder="Enter Sold Value"
              onChange={(e) => handleChange("soldValue", e.target.value)}
              variant="outlined"
              fullWidth
              disabled={!payload.isSold}
            />
            {payload.isSold && errors.soldValue && (
              <Typography
                variant="body1"
                style={{ color: "red", fontSize: "10px" }}
              >
                {errors.soldValue}
              </Typography>
            )}
          </FormSection>

          <Grid container alignItems="center" marginBottom={2}>
            <CustomGridItem md={2} sm={3} xs={12}>
              <CustomTypography required>Accounts</CustomTypography>
            </CustomGridItem>
            <CustomGridItem md={7} sm={5} xs={12}>
              <Select
                options={filteredDataAccounts}
                value={
                  payload.destAccId &&
                  filteredDataAccounts.find(
                    (item) => item.value == payload.destAccId
                  )
                }
                onChange={(val) => {
                  if (val.value == "0000") {
                    setLabelOfBank(val?.label);
                    handleChange("destAccId", val?.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      destAccId: null,
                    }));
                  } else {
                    handleChange("destAccId", val?.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      destAccId: null,
                    }));
                  }
                }}
                isDisabled={!payload.isSold}
                placeholder="Select Accounts (Sold)"
              />
              {payload.isSold && errors.destAccId && (
                <Typography
                  variant="body1"
                  style={{ color: "red", fontSize: "10px" }}
                >
                  {errors.destAccId}
                </Typography>
              )}
            </CustomGridItem>
            <CustomGridItem md={3} sm={4} xs={12}>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  history.push("bankaccountmanagement");
                }}
                style={{ fontSize: 15, cursor: "pointer" }}
                underline="none"
              >
                Add/Update Bank Accounts
              </Link>
            </CustomGridItem>
          </Grid>

          <Typography
            variant="body2"
            marginBlock={1}
            fontSize={15}
            fontWeight={700}
            color="red"
            fontFamily="monospace"
          >
            Optional Fields:
          </Typography>

          <Box
            marginBlock={0.5}
            sx={{
              width: "95%",
              padding: "10px",
              backgroundColor: "#f39c12",
              borderRadius: "5px",
            }}
          >
            <Typography
              variant="body2"
              fontSize={14}
              color={"white"}
              fontFamily="monospace"
            >
              If you have disposed off the asset, please enter the sold date and
              value. The difference between the below sold value and the above
              most recent book value will be shown in the Profit/Loss report.
            </Typography>
          </Box>

          <FormSection title="Replacement Value" required={false}>
            <TextField
              hiddenLabel
              value={payload.replacementValue}
              inputMode="numeric"
              type="number"
              name="replacementValue"
              id="replacementValue"
              placeholder="Enter Replacement Value"
              onChange={(e) => handleChange("replacementValue", e.target.value)}
              variant="outlined"
              fullWidth
            />
          </FormSection>

          <FormSection title="Serial Number" required={false}>
            <TextField
              hiddenLabel
              value={payload.serialNumber}
              placeholder="Enter Serial Number"
              name="serialNumber"
              id="serialNumber"
              onChange={(e) => handleChange("serialNumber", e.target.value)}
              variant="outlined"
              fullWidth
            />
          </FormSection>

          <FormSection title="Bought From(Only valid for tangible assets)" required={false}>
            <TextField
              hiddenLabel
              value={payload.boughtFrom}
              placeholder="Enter Bought From"
              name="boughtFrom"
              id="boughtFrom"
              onChange={(e) => handleChange("boughtFrom", e.target.value)}
              variant="outlined"
              fullWidth
            />
          </FormSection>

          <FormSection title="Description of the asset" required={false}>
            <textarea
              style={{
                borderWidth: "1px",
                borderColor: "lightgray",
                borderRadius: "4px",
                width: "95%",
                padding: "10px",
              }}
              name="description"
              id="description"
              value={payload.description}
              onChange={(e) => handleChange("description", e.target.value)}
            />
          </FormSection>

          <FormSection title="Invoice/Receipt Photo" required={false}>
            <input
              type="file"
              accept="image/*,application/pdf"
              style={{
                border: "1px solid lightgray",
                padding: "10px",
                borderRadius: "4px",
                width: "95%",
              }}
              onChange={handleFileChangeInvoiceReceipt}
            />
            {payload?.invoiceReceipt && typeof(payload?.invoiceReceipt) == "string" ? <em>{payload?.invoiceReceipt?.replace("images/customer/", "")}</em> : <em>{payload?.invoiceReceipt?.name}</em>}
          </FormSection>

          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Button
              className="muiCustomButton"
              variant="outlined"
              onClick={() => history.push("/asset")}
            >
              Go Back
            </Button>

            <Button
              className="GreenBTN"
              variant="filled"
              onClick={handleSaveAsset}
            >
              {localStorage.getItem("isAssetUpdate") ? "Update" : "Add"} Asset
            </Button>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default AddUpdateAsset;
