import React from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import borrowerLogin from "./assets/images/borrowerlogin.png";
import companylogo from "./assets/images/companylogo.svg";
import { auth } from "./firebase/config";

const BorrowerAccessPage = () => {
  const history = useHistory();

  return (
    <>
      <Typography
        variant="h6"
        className="title"
        textAlign={"left"}
        marginLeft={2}
        padding={1}
        component={"div"}
      >
        <img src={companylogo} alt="logo" />
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "90vh",
          width: "100%",
          backgroundColor: "#f8f9fa",
          fontFamily: "Lato, sans-serif",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            paddingLeft={20}
            paddingRight={10}
            style={{ margin: "auto 0px" }}
          >
            <Stack spacing={3}>
              <strong
                style={{
                  fontFamily: "Lato",
                  fontSize: "20px",
                }}
              >
                Dear Borrower,
              </strong>
              <p
                style={{
                  fontFamily: "Lato",
                  fontSize: "16px",
                }}
              >
                We have your details registered with us and would like to inform
                you that our web application is exclusively designed for lenders
                and lending companies.
                <br />
                <br />
                For borrowers, we provide access to all relevant data through
                our mobile app, which allows you to track your loan details,
                payment dues, and other information conveniently.
                <br />
                <br />
                Please download the mobile app using the links below to access
                your data:
                <br />
                <a
                  href="https://play.google.com/store/apps/details?id=com.lendstack&hl=en_IN"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                    alt="Download App"
                  />
                </a>
                <br />
                <br />
              </p>
              <strong
                style={{
                  fontFamily: "Lato",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                Thank you for your understanding!
              </strong>
              <Button
                className="muiCustomButton"
                variant="outlined"
                onClick={() => {
                  localStorage.removeItem("loggedinUser");
                  localStorage.removeItem("userid");
                  localStorage.removeItem("loggedinUserCompany");
                  localStorage.clear();
                  auth.signOut();
                  history.push("/");
                }}
                style={{ maxWidth: "300px", width: "200px" }}
              >
                Logout
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={6} paddingLeft={10} paddingRight={20}>
            <img
              src={borrowerLogin}
              loading="lazy"
              width={"500px"}
              height={"400px"}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default BorrowerAccessPage;
