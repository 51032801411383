import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Typography,
  Box,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Paper,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Snackbar,
  Alert,
  Link,
} from "@mui/material";
import "date-fns";
import Select from "react-select";
import DateRangePicker from "react-daterange-picker";
import { extendMoment } from "moment-range";
import originalMoment from "moment";
import {
  CalendarMonth,
  DeleteSharp,
  EditSharp,
  Info,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Sidebar from "../sidebar/sidebar";
import { companylogo } from "../../assets/images";
import {
  getAllExpensesDataAPI,
  getAllExpensesTypeAPI,
  addExpenseAPI,
  updateExpenseAPI,
  deleteExpenseAPI,
  addExpenseTypeAPI,
  getAllBankAccountsDataAPI,
  addBankAccountAPI,
} from "../../service/service";
import "./Expenses.scss";
import Axios from "axios";
import { BaseUrl } from "../../Environment";

const moment = extendMoment(originalMoment);

const Expenses = () => {
  const history = useHistory();
  const loggedinUser = JSON.parse(localStorage.getItem("loggedinUser"));

  const filterArrays = (apiArr, staticArr) => {
    // Combine both arrays
    const combinedArr = [...apiArr, ...staticArr];

    // Group by label
    const groupedByLabel = combinedArr.reduce((acc, current) => {
      acc[current.label] = acc[current.label] || [];
      acc[current.label].push(current);
      return acc;
    }, {});

    // Filter groups to only include API data if both API and static data are present
    const filteredArr = Object.values(groupedByLabel).reduce((acc, group) => {
      const hasApiData = group.some((item) => apiArr.includes(item));
      const apiGroup = group.filter((item) => apiArr.includes(item));
      const staticGroup = group.filter((item) => staticArr.includes(item));

      if (hasApiData) {
        // If API data is present, use only API data
        acc.push(...apiGroup);
      } else {
        // If no API data, use static data
        acc.push(...staticGroup);
      }

      return acc;
    }, []);

    return filteredArr;
  };

  const indirectCategories = [
    { label: "Travel & Accommodation", value: "S1" },
    { label: "Legal Fees/Dues", value: "S2" },
    { label: "Supplies & Maintenance", value: "S3" },
    { label: "Miscellaneous", value: "S4" },
  ];

  const directCategories = [
    { label: "Interest Paid on Debt", value: "S5" },
    { label: "Salaries and Benefits", value: "S6" },
    { label: "Courier/Postage", value: "S7" },
    { label: "Rent", value: "S8" },
    { label: "Utilities", value: "S9" },
    { label: "Equipment Buying & Leasing", value: "S10" },
    { label: "Depreciation", value: "S11" },
    { label: "Insurance", value: "S12" },
    { label: "Bank Charges", value: "S13" },
    { label: "Advertising and Promotion", value: "S14" },
  ];

  const optionsOfAccount = [{ label: "Cash", value: "0000" }];

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [toastMsg, setToastMsg] = useState({
    msg: "",
    time: 2000,
    variant: "success",
  });

  const [payload, setPayload] = useState({
    amount: "",
    note: "",
    transactionDate: moment().format("YYYY-MM-DD"),
  });

  const [errors, setErrors] = useState({
    expenseTypeId: null,
    amount: "",
    transactionDate: "",
    accountId: "",
  });

  const [labelOfType, setLabelOfType] = useState("");
  const [labelOfBank, setLabelOfBank] = useState("");
  const [accountId, setAccountId] = useState("");
  const [file, setFile] = useState("");
  const [loanId, setLoanId] = useState("");
  const [loansData, setLoansData] = useState([]);
  const [expenseId, setExpenseId] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [expensesData, setExpensesData] = useState([]);
  const [expensesTypeData, setExpensesTypeData] = useState([]);
  const [directExpensesTypeData, setDirectExpensesTypeData] = useState([]);
  const [indirectExpensesTypeData, setIndirectExpensesTypeData] = useState([]);
  const [deleteExpenseModal, setDeleteExpenseModal] = useState(false);
  const [dateRangeModal, setDateRangeModal] = useState(false);
  const [addUpdateModal, setAddUpdateModal] = useState(false);
  const [dateRange, setDateRange] = useState();
  const [expenseTypeFilter, setExpenseTypeFilter] = useState(null);
  const [accountIdFilter, setAccountIdFilter] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [directIndirect, setDirectIndirect] = React.useState("Direct");
  const [bankAccountData, setBankAccountData] = useState([]);

  const handleDirectIndirect = (event) => {
    setExpenseId(null);
    setCategoryId(null);
    setDirectIndirect(event.target.value);
  };

  const fetchLoansData = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    const headers = {
      Authorization: "Bearer " + token,
    };

    const payload = {
      loanStatus: "Open",
      loanPaymentStatus: null,
      companyId: loggedinUser.companyId,
    };
    try {
      const resp = await Axios.post(BaseUrl + "/loan/getAllLoans", payload, {
        headers: headers,
      });

      if (resp?.status == 200 && resp?.data) {
        setLoansData(
          resp?.data?.map((item) => ({
            label: `${item?.customer?.firstName} ${item?.customer?.lastName} ${
              item?.customer?.cust_id ? `(${item?.customer.cust_id})` : ""
            }(Loan : ${item?.loanId})`,
            value: item?.loanId,
          }))
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchExpensesData = async () => {
    try {
      const companyId = loggedinUser?.companyId;
      const userId = loggedinUser?.userId;
      const userType = loggedinUser?.userType;

      const expensesRes = await getAllExpensesDataAPI({
        companyId,
        userId,
        userType,
        expenseTypeFilter,
        accountIdFilter,
        startDateFilter: dateRange?.start
          ? dateRange.start.startOf("d").format("YYYY-MM-DD HH:mm:ss")
          : null,

        endDateFilter: dateRange?.end
          ? dateRange.end.endOf("d").format("YYYY-MM-DD HH:mm:ss")
          : null,
      });

      if (expensesRes?.status == 200 && expensesRes?.data) {
        setExpensesData(expensesRes.data);
      } else {
        setToastMsg({
          msg: "No data available.",
          time: 2000,
          variant: "error",
        });

        setTimeout(() => {
          handleOpen();
        }, 500);

        setExpensesData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchExpenseTypesData = async () => {
    try {
      const companyId = loggedinUser?.companyId;

      const expenseTypesRes = await getAllExpensesTypeAPI(companyId);

      if (expenseTypesRes?.status == 200 && expenseTypesRes?.data) {
        setExpensesTypeData(expenseTypesRes.data?.map((item) => ({
          label: item.title,
          value: item.categoryId,
        })));
        const apiDirectExpensesType = expenseTypesRes?.data
          ?.filter((data) => data?.type == "Direct")
          .map((item) => ({
            label: item.title,
            value: item.categoryId,
          }));

        const apiIndirectExpensesType = expenseTypesRes?.data
          ?.filter((data) => data?.type == "Indirect")
          .map((item) => ({
            label: item.title,
            value: item.categoryId,
          }));

        setDirectExpensesTypeData(
          filterArrays(apiDirectExpensesType, directCategories)
        );
        setIndirectExpensesTypeData(
          filterArrays(apiIndirectExpensesType, indirectCategories)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBAData = async () => {
    try {
      const companyId = loggedinUser?.companyId;

      const data = {
        companyId,
        searchQuery: "",
      };

      const resp = await getAllBankAccountsDataAPI(data);

      if (resp?.data?.length > 0) {
        setBankAccountData(resp?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchLoansData();
    fetchBAData();
  }, []);

  useEffect(() => {
    fetchExpensesData();
  }, [expenseTypeFilter, accountIdFilter, dateRange]); // Add dependencies as necessary

  useEffect(() => {
    fetchExpenseTypesData();
  }, [directIndirect]); // Add dependencies as necessary

  const handleChange = (key, value) => {
    setPayload((prev) => ({ ...prev, [key]: value }));
  };

  const handleSaveExpense = async () => {
    const userId = loggedinUser?.userId;
    const companyId = loggedinUser?.companyId;
    const { amount, note, transactionDate } = payload;

    const newErrors = {
      expenseTypeId: (categoryId ? !categoryId : !expenseId)
        ? "This field is required"
        : "",
      amount: !payload.amount ? "This field is required" : "",
      transactionDate: !payload.transactionDate ? "This field is required" : "",
      accountId: !accountId ? "This field is required" : "",
    };

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error != "");

    if (!hasErrors) {
      try {
        let catePayload, bankAccountPayload, cateRes, bankRes;

        if (labelOfType) {
          catePayload = {
            title: labelOfType,
            description: `Desc ${directIndirect}`,
            type: directIndirect,
            color: "#0a2861",
            companyId,
            createdByUserId: userId,
            updatedByUserId: userId,
          };

          cateRes = await addExpenseTypeAPI(catePayload);

          if (cateRes?.status == 200) {
            setLabelOfType("");
          }
        }

        if (labelOfBank) {
          bankAccountPayload = {
            code: "0000",
            bankName: labelOfBank,
            companyId,
            createdByUserId: userId,
            updatedByUserId: userId,
          };

          bankRes = await addBankAccountAPI(bankAccountPayload);

          if (bankRes?.status == 200) {
            setLabelOfBank("");
          }
        }

        let expensePayload = new FormData();
        expensePayload.append("amount", amount);
        expensePayload.append("note", note);
        expensePayload.append("transactionDate", transactionDate);
        expensePayload.append(
          "categoryId",
          cateRes?.data?.result?.categoryId
            ? cateRes?.data?.result?.categoryId
            : expenseId
            ? expenseId
            : categoryId
        );
        expensePayload.append("companyId", companyId);
        expensePayload.append("userId", userId);
        expensePayload.append("createdByUserId", userId);
        expensePayload.append("updatedByUserId", userId);
        expensePayload.append("loanId", loanId);
        expensePayload.append(
          "accountId",
          bankRes?.data?.result?.accountId
            ? bankRes?.data?.result?.accountId
            : accountId
        );
        expensePayload.append("invoiceReceipt", file);
        if (isUpdate) {
          expensePayload.append("transactionId", transactionId);
        }

        const res = isUpdate
          ? await updateExpenseAPI(expensePayload)
          : await addExpenseAPI(expensePayload);

        if (res?.status == 200) {
          setAddUpdateModal(false);
          setToastMsg({
            msg: `Expense ${isUpdate ? "Updated" : "Added"} Successfully.`,
            time: 4000,
            variant: "success",
          });

          setTimeout(() => {
            handleOpen();
          }, 500);
          setTimeout(() => {
            fetchExpenseTypesData();
            fetchBAData();
            fetchExpensesData();
          }, 1000);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleEdit = (data) => {
    setExpenseId(null);
    setPayload({
      amount: data.amount,
      note: data.note,
      transactionDate: moment(data.transactionDate).format("YYYY-MM-DD"),
    });
    setAccountId(data?.accountId);
    setLoanId(data?.loanId);
    setFile(data?.invoiceReceipt);
    setCategoryId(data?.categoryId);
    setTransactionId(data?.transactionId);
    setIsUpdate(true);
    setAddUpdateModal(true);
  };

  const handleDelete = async () => {
    try {
      await deleteExpenseAPI(transactionId);
      setDeleteExpenseModal(false);

      setToastMsg({
        msg: "Expense Deleted Successfully.",
        time: 3000,
        variant: "success",
      });

      setTimeout(() => {
        handleOpen();
      }, 500);

      setTimeout(() => {
        fetchExpensesData();
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const AccountsSelectData = [
    ...bankAccountData?.map((item) => ({
      label: item.bankName,
      value: item.accountId,
    })),
    ...optionsOfAccount,
  ];

  const uniqueAccounts = AccountsSelectData.reduce((acc, item) => {
    if (
      !acc.has(item.label) ||
      (acc.has(item.label) && acc.get(item.label).value == "0000")
    ) {
      acc.set(item.label, item);
    }
    return acc;
  }, new Map());

  const filteredDataAccounts = Array.from(uniqueAccounts.values());

  const AccountsFilterData = [
    ...bankAccountData?.map((item) => ({
      label: item.bankName,
      value: item.accountId,
    })),
  ];

  const handleKeyDown = (event) => {
    event.preventDefault(); // Block any keyboard interaction
  };

  return (
    loansData &&
    expensesTypeData && (
      <>
        <Sidebar />

        <Snackbar
          open={open}
          autoHideDuration={toastMsg.time}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={toastMsg.variant}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {toastMsg.msg}
          </Alert>
        </Snackbar>

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={"10px 100px"}
        >
          <img src={companylogo} alt="logo" />
        </Stack>

        <Stack
          className="expensespage"
          spacing={1}
          padding={"10px 20px 10px 100px"}
        >
          <Grid container>
            <Grid item md={6} sm={4}></Grid>
            <Grid item md={3} sm={4} sx={{ padding: "0 10px" }}>
              <Button
                className="muiCustomButton"
                variant="outlined"
                onClick={() => history.push("expensetypes")}
              >
                Manage Expense Types
              </Button>
            </Grid>
            <Grid item md={3} sm={4} sx={{ padding: "0 10px" }}>
              <Button
                className="GreenBTN"
                variant="filled"
                onClick={() => {
                  setPayload({
                    amount: "",
                    note: "",
                    transactionDate: moment().format("YYYY-MM-DD"),
                  });
                  setCategoryId(null);
                  setExpenseId(null);
                  setLoanId("");
                  setAccountId("");
                  setIsUpdate(false);
                  setAddUpdateModal(true);
                }}
              >
                + Add Expense
              </Button>
            </Grid>
          </Grid>

          <Box sx={{ width: "98%", padding: "10px", backgroundColor: "#fff" }}>
            <Typography
              variant="body2"
              marginBlock={1}
              fontSize={15}
              fontWeight={700}
            >
              Filter Expenses
            </Typography>
            <Grid container alignItems={"center"}>
              <Grid
                item
                md={4}
                xs={12}
                sx={{ padding: "0 10px", marginTop: "20px" }}
              >
                <Select
                  options={expensesTypeData}
                  placeholder="Select Expense Types"
                  value={
                    expenseTypeFilter &&
                    expensesTypeData.find((item) => item.value == expenseTypeFilter)
                  }
                  onChange={(val) => setExpenseTypeFilter(val?.value)}
                />
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
                sx={{ padding: "0 10px", marginTop: "20px" }}
              >
                <Select
                  options={AccountsFilterData}
                  placeholder="Select Accounts"
                  value={
                    accountIdFilter &&
                    AccountsFilterData?.find(
                      (item) => item.value == accountIdFilter
                    )
                  }
                  onChange={(val) => setAccountIdFilter(val?.value)}
                />
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
                sx={{ padding: "0 10px", marginTop: "20px" }}
              >
                <Button
                  onClick={() => setDateRangeModal(true)}
                  variant="outlined"
                  fullWidth
                  startIcon={<CalendarMonth />}
                >
                  Select Date Range
                </Button>
              </Grid>
            </Grid>

            <Stack
              mt={2}
              mr={1}
              direction={"row"}
              alignSelf={"flex-end"}
              justifyContent={"flex-end"}
            >
              <Button
                className="muiCustomButton"
                onClick={() => {
                  setAccountIdFilter("");
                  setExpenseTypeFilter(null);
                  setDateRange();
                }}
                variant="outlined"
              >
                Reset
              </Button>
            </Stack>
          </Box>

          <Card style={{ marginTop: "10px" }}>
            <div className="tableLaylout">
              <Table aria-label="sticky table" className="table">
                <TableHead>
                  <TableRow className="tablHeadRowPayment">
                    <TableCell className="tablHeadCellPayment">Date</TableCell>
                    <TableCell className="tablHeadCellPayment">Type</TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Amount
                    </TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Accounts
                    </TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Description
                    </TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Is Recurring
                    </TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Recurring Type
                    </TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {expensesData.map((data) => {
                    const {
                      transactionId,
                      amount,
                      note,
                      transactionDate,
                      categoryId,
                      accountId,
                    } = data;

                    const selectedType = expensesTypeData.find(
                      (item) => item.value == categoryId
                    );

                    const selectedAccount = bankAccountData.find(
                      (item) => item.accountId == accountId
                    );

                    return (
                      <TableRow key={transactionId} className="tableRow">
                        <TableCell>
                          {originalMoment(transactionDate).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>{selectedType?.label}</TableCell>
                        <TableCell>{amount}</TableCell>
                        <TableCell>{selectedAccount?.bankName}</TableCell>
                        <TableCell>{note}</TableCell>
                        <TableCell>No</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>
                          <Stack direction={"row"} spacing={1}>
                            <IconButton
                              color="primary"
                              aria-label="update"
                              onClick={() => handleEdit(data)}
                            >
                              <EditSharp />
                            </IconButton>
                            <IconButton
                              color="error"
                              aria-label="delete"
                              onClick={() => {
                                setTransactionId(transactionId);
                                setDeleteExpenseModal(true);
                              }}
                            >
                              <DeleteSharp />
                            </IconButton>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </Card>
        </Stack>

        {/* Delete Expense Modal */}
        <Dialog
          open={deleteExpenseModal}
          onClose={() => setDeleteExpenseModal(false)}
        >
          <DialogTitle>Delete Expense</DialogTitle>
          <DialogContent>
            <Typography fontSize={15} variant="h6">
              Are you sure, You want to delete this expense record?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <Button
                variant="outlined"
                onClick={() => setDeleteExpenseModal(false)}
              >
                Cancel
              </Button>
              <Button color="error" variant="contained" onClick={handleDelete}>
                Delete
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>

        {/* Date Range Modal */}
        <Dialog open={dateRangeModal} onClose={() => setDateRangeModal(false)}>
          <DialogTitle>Select Date</DialogTitle>
          <DialogContent>
            <DateRangePicker
              value={dateRange}
              onSelect={setDateRange}
              singleDateRange
            />
            <Stack spacing={1} marginTop={1}>
              <Typography fontSize={15} textAlign={"center"} variant="h6">
                Start Date:{" "}
                {dateRange?.start
                  ? dateRange.start.startOf("d").format("DD/MM/YYYY")
                  : "-"}
              </Typography>
              <Typography fontSize={15} textAlign={"center"} variant="h6">
                End Date:{" "}
                {dateRange?.end
                  ? dateRange.end.startOf("d").format("DD/MM/YYYY")
                  : "-"}
              </Typography>
            </Stack>
          </DialogContent>
        </Dialog>

        {/* Add/Update Modal */}
        <Dialog
          fullWidth
          maxWidth="sm"
          open={addUpdateModal}
          onClose={() => setAddUpdateModal(false)}
        >
          <DialogTitle>{isUpdate ? "Update" : "Add"} Expense</DialogTitle>
          <DialogContent>
            <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <Paper elevation={3} sx={{ p: 2, width: "100%" }}>
                <Stack spacing={0.5} marginBottom={1}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Direct/Indirect Expense Types
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={directIndirect}
                      onChange={handleDirectIndirect}
                    >
                      <FormControlLabel
                        value="Direct"
                        control={<Radio />}
                        label="Direct"
                      />
                      <FormControlLabel
                        value="Indirect"
                        control={<Radio />}
                        label="Indirect"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
                <Stack spacing={0.5} marginBottom={1}>
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Typography variant="body1">
                      Expense Type
                      <span style={{ color: "red", fontSize: 15 }}>*</span>
                    </Typography>
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("expensetypes");
                      }}
                      style={{ fontSize: 12, cursor: "pointer" }}
                      underline="none"
                    >
                      {"Add/Update Expense Types"}
                    </Link>
                  </Stack>

                  <Select
                    options={
                      directIndirect == "Direct"
                        ? directExpensesTypeData
                        : indirectExpensesTypeData
                    }
                    value={
                      expenseId
                        ? expenseId &&
                          expensesTypeData.find((item) => item.value == expenseId)
                        : categoryId &&
                          expensesTypeData.find((item) => item.value == categoryId)
                    }
                    onChange={(item) => {
                      if (typeof item?.value != "string") {
                        setExpenseId(item?.value);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          expenseTypeId: null,
                        }));
                      } else {
                        setLabelOfType(item?.label);
                        setExpenseId(item?.value);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          expenseTypeId: null,
                        }));
                      }
                    }}
                    placeholder="Expense Type"
                  />
                  {errors.expenseTypeId && (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {errors.expenseTypeId}
                    </Typography>
                  )}
                </Stack>

                <Stack spacing={0.5} marginBottom={1}>
                  <Typography variant="body1">
                    Expense Amount
                    <span style={{ color: "red", fontSize: 15 }}>*</span>
                  </Typography>
                  <TextField
                    hiddenLabel
                    value={payload.amount}
                    inputMode="numeric"
                    type="number"
                    placeholder="Enter Expense Amount"
                    onChange={(e) => handleChange("amount", e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                  {errors.amount && (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {errors.amount}
                    </Typography>
                  )}
                </Stack>
                <Stack spacing={0.5} marginBottom={1}>
                  <Typography variant="body1">
                    Expense Date
                    <span style={{ color: "red", fontSize: 15 }}>*</span>
                  </Typography>
                  <input
                    type="date"
                    onKeyDown={handleKeyDown} // Block typing
                    style={{
                      borderWidth: "1px",
                      borderColor: "lightgray",
                      borderRadius: "4px",
                      width: "95%",
                      padding: "10px",
                    }}
                    value={payload.transactionDate}
                    onChange={(e) =>
                      handleChange("transactionDate", e.target.value)
                    }
                  />
                  {errors.transactionDate && (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {errors.transactionDate}
                    </Typography>
                  )}
                </Stack>
                <Stack spacing={0.5} marginBottom={1}>
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Typography variant="body1">
                      Accounts
                      <span style={{ color: "red", fontSize: 15 }}>*</span>
                    </Typography>
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("bankaccountmanagement");
                      }}
                      style={{ fontSize: 12, cursor: "pointer" }}
                      underline="none"
                    >
                      {"Add/Update Bank Accounts"}
                    </Link>
                  </Stack>
                  <Select
                    options={filteredDataAccounts}
                    value={
                      accountId &&
                      filteredDataAccounts.find(
                        (item) => item.value == accountId
                      )
                    }
                    onChange={(val) => {
                      if (val.value == "0000") {
                        setLabelOfBank(val?.label);
                        setAccountId(val?.value);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          accountId: null,
                        }));
                      } else {
                        setAccountId(val?.value);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          accountId: null,
                        }));
                      }
                    }}
                    placeholder="Select Accounts"
                  />
                  {errors.accountId && (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {errors.accountId}
                    </Typography>
                  )}
                </Stack>
                <Stack spacing={0.5} marginBottom={1}>
                  <Typography variant="body1">Invoice/Receipt</Typography>
                  <input
                    type="file"
                    accept="image/*,application/pdf"
                    style={{
                      border: "1px solid lightgray",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                    onChange={handleFileChange}
                  />
                  {typeof file == "string" && <span>{file}</span>}
                </Stack>
                <Stack spacing={0.5} marginBottom={1}>
                  <Typography variant="body1">Expense Description</Typography>
                  <TextField
                    hiddenLabel
                    value={payload.note}
                    placeholder="Enter Expense Description"
                    onChange={(e) => handleChange("note", e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Stack>
                <Stack spacing={0.5} marginBottom={1}>
                  <Stack spacing={1} direction={"row"} alignItems={"center"}>
                    <Typography variant="body1">Link to Loan</Typography>
                    <Tooltip title="If this income is for a specific loan, you can select the loan below. Otherwise leave the below empty.">
                      <IconButton>
                        <Info />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  <Select
                    options={loansData}
                    value={loansData.find((item) => item.value == loanId)}
                    onChange={(val) => setLoanId(val?.value)}
                    placeholder="Select Loan"
                  />
                </Stack>
              </Paper>
            </Box>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <Button
                variant="outlined"
                onClick={() => setAddUpdateModal(false)}
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSaveExpense}>
                {isUpdate ? "Update" : "Add"}
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </>
    )
  );
};

export default Expenses;
