import React, { useCallback, useEffect, useState } from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.scss";

import AddUser from "./components/AddUser/addUser";
import AddCustomer from "./components/AllNewCustomers/AddCustomers/AddCustomer";
import CollectionReport from "./components/CollectionReports/CollectionReport";
import EditCompanyProfile from "./components/company profile/EditCompanyProfile/editcompanyprofile";
import CompanyProfileDetails from "./components/CompanyProfileDetails/CompanyProfileDetails";
import AddLoanWithoutInterest from "./components/CustomersInfoRelatedLoans/AddLoanWithoutInterest/AddLoanWithoutInterest";
import Customer from "./components/CustomersInfoRelatedLoans/Customer";
import CustomerDetails from "./components/CustomersInfoRelatedLoans/CustomerDetails/CustomerDetails";
import Dashboard from "./components/Dashboard/Dashboard";
import EditCollectorProfile from "./components/Editcollector/editcollector";
import EditLoan from "./components/LoanType/EditLoan/EditLoan";
import AddLoanType from "./components/LoanType/AddLoanType/AddLoanType";
import Loan from "./components/LoanType/Loan";
import Login from "./components/login/login";
import CustomerWiseReport from "./components/Reports/CustomerWiseReport/index";
import LoanWiseReport from "./components/Reports/LoanWiseReport/index";
import DisburseReport from "./components/Reports/DisburseReport";
import RejectionReport from "./components/Reports/RejectionReport";
import Usermanagement from "./components/usermanagement/usermanagement";
import AllNewCustomers from "./components/AllNewCustomers/AllNewCustomer";
import ForgetPassword from "./components/login/ForgetPassword/ForgetPassword";
import ResetPassword from "./components/login/ResetPassword/ResetPassword";
import Help from "./components/Help/Help";
import NotFound from "./components/NotFound/NotFound";
import NetworkDetector from "./NetworkDetector";
import TermsOfService from "./components/modals/TermsOfService/TermsOfService";
import PrivacyPolicy from "./components/modals/PrivacyPolicy/PrivacyPolicy";
import OtpSignin from "./components/login/OtpSignin/OtpSignin";
import GoogleSignin from "./components/login/GoogleSignin/GoogleSignin";
import { auth } from "./firebase/config";
import LoadingPage from "./components/modals/LoadingPage/LoadingPage";
import SignUp1 from "./components/login/Signup/Signup";
import {
  checkUser,
  creategoogleuser,
  getCompanyProfile,
  getUserDetails,
  notPaidLoginCheck,
} from "./api";
import csvImportExport from "./components/csvImportExport";
import loanCsvImport from "./components/loanCsvImport";
import Pricing from "./components/Pricing";
import Expenses from "./components/Expenses/Expenses";
import ExpenseTypes from "./components/Expenses/ExpenseTypes";
import OtherIncome from "./components/OtherIncome/OtherIncome";
import OtherIncomeTypes from "./components/OtherIncome/OtherIncomeTypes";
import BankAccountManagement from "./components/BankAccountManagement/BankAccountManagement";
import AssetTypes from "./components/AssetManagement/AssetTypes";
import Assets from "./components/AssetManagement/Assets";
import AddUpdateAsset from "./components/AssetManagement/AddUpdateAsset";
import PrintDocs from "./components/PrintDocs/PrintDocs";
import LoanDisbursement from "./components/LoanDisbursement/LoanDisbursement";
import Settings from "./components/Settings/Settings";
import ProtectedRoute from "./ProtectedRoute";
import NotFoundPage from "./NotFoundPage";
import BorrowerAccessPage from "./BorrowerAccessPage";
import { getUserTypeDetails, getAllSettings } from "./utilsFunctions";
import UpdateAdminProfile from './components/updateAdmin/updateadminprofile'
import DetailedAnalysisReport from "./components/Reports/DetailedAnalysisReport";

const App = () => {
  const history = useHistory();
  const loggedinUser = JSON.parse(localStorage.getItem("loggedinUser"));

  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);

  const companyData = () => {
    getCompanyProfile()
      .then((resp) => {
        if (resp) {
          localStorage.setItem("companyCountry", JSON.stringify(resp.country));
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const getStatus = (planenddate) => {
    const currentDate = new Date();
    const endDate = new Date(planenddate);
    const diffTime = endDate - currentDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Difference in days

    if (diffDays < 0) {
      return {
        message: "Your plan has expired. Please renew it from upgrade tab.",
        expired: true,
        abouttoexpire: false,
      };
    } else if (diffDays <= 10) {
      return {
        message:
          "Your plan is about to expire. Please renew it for continue using/",
        expired: false,
        abouttoexpire: true,
      };
    } else {
      return {
        message: "Your plan is active.",
        expired: false,
        abouttoexpire: false,
      };
    }
  };

  const LoggedinUser = () => {
    getUserDetails(JSON.parse(localStorage.getItem("firebaseUid")))
      .then((resp) => {
        if (resp?.userType == "Collection Agent") {
          toast.info(
            "Access is Denied. You have been added as Collection Agent by Your Company.",
            {
              position: "top-center",
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
            }
          );
        } else {
          if (resp.userId && resp.firstName && resp.lastName) {
            if (resp?.company?.planEndDate) {
              const { message, expired, abouttoexpire } = getStatus(
                resp?.company?.planEndDate
              );

              if (expired) {
                toast.info(message, {
                  position: "top-center",
                  autoClose: 10000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: false,
                });
              } else if (abouttoexpire) {
                toast.info(message, {
                  position: "top-center",
                  autoClose: 10000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: false,
                });
              }
            }
            notPaidLoginCheck(resp.userId);
            localStorage.setItem("userid", resp.userId);
            localStorage.setItem("loggedinUser", JSON.stringify(resp));
            localStorage.setItem(
              "loggedinUserCompany",
              JSON.stringify(resp.companyId)
            );
            getUserTypeDetails(resp.userType, resp.companyId,resp.userId);
            getAllSettings();
            companyData();

            history.push("/dashboard");
            // setTimeout(() => {
            //   window.location.reload(true);
            // }, 500);
          } else {
            history.push("/signup/register");
          }
        }
      })
      .catch((error) => {
        console.log("login error is:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onAuthStateChanged = useCallback((_user) => {
    if (_user) {
      setLoading(true);
    } else {
      setLoading(false);
      setUser(null);
    }

    auth.currentUser
      .getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        localStorage.setItem("token", JSON.stringify(idToken));

        checkUser(_user.uid, _user.email)
          .then((res1) => {
            if (res1?.code === 200) {
              setUser(_user);
              setLoading(false);
              if (!localStorage.getItem("loggedinUser")) {
                LoggedinUser(); // Load user data only on login
              } else {
                getUserTypeDetails(loggedinUser?.userType, loggedinUser?.companyId,loggedinUser?.userId);
                getAllSettings();
              }
            } else {
              const userType = localStorage.getItem("userTypeAY");

              const userData = {
                userEmail: _user.email,
                firebaseUid: _user.uid,
                userType: userType
              };

              creategoogleuser(userData)
                .then(async (res) => {
                  if (res?.code === 200) {
                    setUser(_user);
                  } else {
                    await auth.signOut();
                    localStorage.removeItem("token");
                  }
                })
                .catch((err) => {
                  console.log({ err });
                });
            }
          })
          .catch((err1) => {
            console.log({ err1 });
          });
      })
      .catch(function (error) {
        console.log({ error });
      });
  }, []);

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
  }, [onAuthStateChanged]);

  useEffect(() => {
    if (user) {
      localStorage.setItem("firebaseUid", JSON.stringify(user.uid));
      localStorage.setItem("defaultEmail", JSON.stringify(user.email));
    }
  }, [user]);

  if (loading) {
    return <LoadingPage isLoading={loading} />;
  }

  if (loggedinUser?.userType == "borrower") {
    return <BorrowerAccessPage />;
  }

  // Helper function to check if a token exists in localStorage
  const isAuthenticated = () => {
    return !!localStorage.getItem("token"); // returns true if token exists
  };

  // Helper function to check if a user exists in localStorage
  const isLoggedIn = () => {
    return !!localStorage.getItem("loggedinUser"); // returns true if user exists
  };

  // Helper function to check if country data exists in localStorage
  const isCountryAvl = () => {
    return !!JSON.parse(localStorage.getItem("country")); // returns true if country data exists
  };

  return (
    <Router>
      <Switch>
        {/* Common Routes */}
        {/* <Route
          exact
          path={[
            "/login",
            "/otpsignin",
            "/googlesignin",
            "/signup/register",
            "/signup",
            "/aboutus",
            "/contact",
          ]}
          component={Header}
        /> */}
        <Route exact path="/settings" render={() => (isLoggedIn() ? <Settings /> : <Redirect to="/notfound" />)} />

        <Route exact path="/login" component={Login} />
        <Route exact path="/otpsignin" render={() => (isLoggedIn() ? <Redirect to="/dashboard" /> : isCountryAvl() ? <OtpSignin /> : <Redirect to="/login" />)} />
        {/* <Route exact path="/otpsignin" component={OtpSignin} /> */}
        
        <Route exact path="/googlesignin" render={() => (isLoggedIn() ? <Redirect to="/dashboard" /> : isCountryAvl() ? <GoogleSignin /> : <Redirect to="/login" />)} />
        {/* <Route exact path="/googlesignin" component={GoogleSignin} /> */}
        <Route exact path="/forgetpassword" component={ForgetPassword} />
        <Route
          exact
          path="/completemyregistration"
          component={ForgetPassword}
        />
        <Route exact path="/reset" component={ResetPassword} />
        <Route exact path="/signup/register" render={() => (isLoggedIn() ? <Redirect to="/dashboard" /> : isAuthenticated() ? <SignUp1 /> : <Redirect to="/login" />)} />
        {/* <Route exact path="/signup/register" component={SignUp1} /> */}
        <Route exact path="/help" component={Help} />
        <Route exact path="/termsofservice" component={TermsOfService} />
        <Route exact path="/privacypolicy" component={PrivacyPolicy} />
        <Route exact path="/notfound" component={NotFoundPage} />

        {/* Automatically redirect based on token */}
        <Route
          exact
          path="/"
          render={() => (isLoggedIn() ? <Redirect to="/dashboard" /> : <Redirect to="/login" />)}
        />

        {/* Common with User/DB Details */}
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/usermanagement" component={Usermanagement} />
        <Route exact path="/companyprofile" component={CompanyProfileDetails} />
        <Route exact path="/pricing" component={Pricing} />
        <Route
          exact
          path="/bankaccountmanagement"
          component={BankAccountManagement}
        />
        <Route exact path="/loantype" component={Loan} />
        <Route
          exact
          path="/customers/customerinfo"
          component={CustomerDetails}
        />

        {/* Protected Routes */}
        <ProtectedRoute
          exact
          path="/addloannointerest"
          component={AddLoanWithoutInterest}
          requiredPermission="addLoan"
        />
        <ProtectedRoute
          exact
          path="/loan/loanCsvImport"
          component={loanCsvImport}
          requiredPermission="addLoan"
        />
        <ProtectedRoute
          exact
          path="/customer"
          component={Customer}
          requiredPermission="viewLoan"
        />
        <ProtectedRoute
          exact
          path="/customer/:filter"
          component={Customer}
          requiredPermission="viewLoan"
        />
        <ProtectedRoute
          exact
          path="/loandisbursement"
          component={LoanDisbursement}
          requiredPermission="approveLoan"
        />
        <ProtectedRoute
          exact
          path="/printdocs"
          component={PrintDocs}
          requiredPermission="printDocs"
        />
        <ProtectedRoute
          exact
          path="/companyprofile/edit"
          component={EditCompanyProfile}
          requiredPermission="editProfile"
        />
        <ProtectedRoute
          exact
          path="/usermanagement/adduser"
          component={AddUser}
          requiredPermission="addUsers"
        />
        <ProtectedRoute
          exact
          path="/usermanagement/editcollector"
          component={EditCollectorProfile}
          requiredPermission="addUsers"
        />
        <ProtectedRoute
          exact
          path="/expenses"
          component={Expenses}
          requiredPermission="Expenses"
        />
        <ProtectedRoute
          exact
          path="/expensetypes"
          component={ExpenseTypes}
          requiredPermission="Expenses"
        />
        <ProtectedRoute
          exact
          path="/otherincome"
          component={OtherIncome}
          requiredPermission="accounting"
        />
        <ProtectedRoute
          exact
          path="/otherincometypes"
          component={OtherIncomeTypes}
          requiredPermission="accounting"
        />
        <ProtectedRoute
          exact
          path="/asset"
          component={Assets}
          requiredPermission="accounting"
        />
        <ProtectedRoute
          exact
          path="/assettypes"
          component={AssetTypes}
          requiredPermission="accounting"
        />
        <ProtectedRoute
          exact
          path="/addupdateasset"
          component={AddUpdateAsset}
          requiredPermission="accounting"
        />
        <ProtectedRoute
          exact
          path="/addloan"
          component={AddLoanType}
          requiredPermission="addLoanTypes"
        />
        <ProtectedRoute
          exact
          path="/loan/editloan"
          component={EditLoan}
          requiredPermission="addLoanTypes"
        />
        <ProtectedRoute
          exact
          path="/allnewcustomers"
          component={AllNewCustomers}
          requiredPermission="viewBorrowers"
        />
        <ProtectedRoute
          exact
          path="/customers/addcustomer"
          component={AddCustomer}
          requiredPermission="addBorrowers"
        />
        <ProtectedRoute
          exact
          path="/customers/csvImportExport"
          component={csvImportExport}
          requiredPermission="addBorrowers"
        />
        <ProtectedRoute
          exact
          path="/collectionreport"
          component={CollectionReport}
          requiredPermission="viewReports"
        />
        <ProtectedRoute
          exact
          path="/collectionreport/:filter"
          component={CollectionReport}
          requiredPermission="viewReports"
        />
        <ProtectedRoute
          exact
          path="/customerwisereport"
          component={CustomerWiseReport}
          requiredPermission="viewReports"
        />
        <ProtectedRoute
          exact
          path="/loanwisereport"
          component={LoanWiseReport}
          requiredPermission="viewReports"
        />
        <ProtectedRoute
          exact
          path="/disbursereport"
          component={DisburseReport}
          requiredPermission="viewReports"
        />
        <ProtectedRoute
          exact
          path="/rejectionreport"
          component={RejectionReport}
          requiredPermission="viewReports"
        />
        <ProtectedRoute
          exact
          path="/detailedanalysisreport"
          component={DetailedAnalysisReport}
          requiredPermission="viewReports"
        />
        <ProtectedRoute exact path="/updateadmin" component={UpdateAdminProfile} requiredPermission="editProfile" />

        {/* Not Found Route */}
        <Route path="**" component={NotFoundPage} />

        {/* Not being used anywhere */}
        {/* <Route exact path="/customers/addloan" component={AddLoan} /> */}
        {/* <Route exact path="/customers/allcustomerlist" component={AllCustomerListView} /> */}
        {/* <Route exact path="/registercompanyprofile" component={CompanyProfile} /> */}
        {/* <Route exact path="/verifynumber" component={VerifyNumber} /> */}
        {/* <Route exact path="/share" component={MessagingService} /> */}
        {/* <Route exact path="/updatepassword" component={UpdatePassword} /> */}
      </Switch>
    </Router>
  );
};

export default NetworkDetector(App);
